import axiosInstance from "../config/api";
import { User, UserStore } from "./user";

export class PaymentStore {
  private user: User | null = null;
  paymentMethod: "PostWire" | "Onsite" | "OnlinePayment" | "StripePayment" | "KonnectPayment" = "Onsite";

  constructor(private UserStore: UserStore) {}

  async createCheckoutSession(amount: number, currency: string, courseId: any) {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("User token is missing");
      return;
    }

    try {
      const response = await axiosInstance.post(
        `/Payments/createCheckoutSession?access_token=${token}`,
        { amount, currency, courseId }
      );

      if (response.status === 200) {
        // Call the tracking form function with payment data
        //await this.addTrackingFormData(courseId, this.paymentMethod, token);
        window.location.href = response.data.sessionUrl;
      } else {
        throw new Error("Failed to create Stripe Checkout session.");
      }
    } catch (error) {
      console.error("Error creating Stripe Checkout session:", error);
    }
  }

  async createWiringOrOnsitePaymentSession(courseId: string, paymentMethod: "PostWire" | "Onsite") {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("User token is missing");
      return;
    }

    try {
      const response = await axiosInstance.post(
        `/Payments/createWiringOrOnsitePaymentSession?access_token=${token}`,
        { courseId, paymentMethod }
      );

      await this.addTrackingFormData(courseId, paymentMethod, token);
      return response;
    } catch (error) {
      console.error("Error creating Wiring/Onsite Payment session:", error);
      throw error;
    }
  }

  private async addTrackingFormData(courseId: string, paymentMethod: "PostWire" | "Onsite" | "OnlinePayment" | "StripePayment" | "KonnectPayment", userToken: string) {
    try {
      const userResponse = await axiosInstance.get(`/Profiles/CurrentUser?access_token=${userToken}`);
      
      if (userResponse.status === 200) {
        const user = userResponse.data.user; 
        const profileId = userResponse.data.userId; 
        // const phone = user.phone ? user.phone.replace("+", "") : "N/A"; 
        const phone = user.phone ? user.phone.replace(/^\+?\d{1,3}/, "") : "N/A";
        const address = user.billingInfo?.streetAddress || "N/A"; 

        await axiosInstance.post(
          `/TrackingForms?access_token=${userToken}`,
          {
            address,
            paymentMethod,
            phone,
            profileId,
            sessionId: courseId,
            step: 2, 
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          },
        );
      }
    } catch (error) {
      console.error("Error adding tracking form data:", error);
    }
  }

  paymentMethodHandler(method: "PostWire" | "Onsite" | "OnlinePayment" | "StripePayment" | "KonnectPayment") {
    this.paymentMethod = method;
  }
}
