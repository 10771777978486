const objectifs = [
    {
        id: "1",
        label: "Find a job",
    },
    {
        id: "2",
        label: "Become a Freelancer",
    },
    {
        id: "3",
        label: "Make a professional retraining",
    },
    {
        id: "4",
        label: "Entrepreneur",
    },
    {
        id: "5",
        label: "Upskill",
    },
    {
        id: "6",
        label: "Increase my revenue",
    },
    {
        id: "7",
        label: "Share knowledge",
    },
    {
        id: "8",
        label: "Other",
    }
];

export default objectifs;