export const formatCurrency = (amount: number, currency: string): string => {
    switch (currency) {
      case "USD":
        return `$${amount.toFixed(2)}`;
      case "EUR":
        return `${amount.toFixed(2)}€`;
      case "CAD":
        return `${amount.toFixed(2)}$ CAD`;
      case "TND":
        return `${amount.toFixed(2)} TND`;
      default:
        return `${amount.toFixed(2)} ${currency}`;
    }
  };
  