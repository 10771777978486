import { useEffect, useState } from "react";
import { getSessionDetails, rateSession } from "src/store/session";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../store";
import { Navbar, Loading, Button } from "src/components/shared";
import RateItem from "./Item";

import "./styles.css";

export default function Rate() {
  const navigate = useNavigate();

  const { id: sessionId } = useParams();
  const { userStore } = useStore();

  useEffect(() => {
    userStore.getUser().then(() => {
      const user = userStore.user;
      if (!user) {
        navigate("/login");
      }
    });
  }, []);

  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState<any>(null);
  const [sStars, setSStars] = useState(0);
  const [rStars, setRStars] = useState(0);
  const [tStars, setTStars] = useState(0);
  const [feedSession, setFeedSession] = useState("");
  const [feedTeacher, setFeedTeacher] = useState("");
  const [feedRoom, setFeedRoom] = useState("");

  useEffect(() => {
    if (sessionId) {
      getSessionDetails(sessionId)
        .then((response) => {
          setLoading(false);
          if (response !== null) {
            setSession(response);
            setSStars(response.rating);
            console.log(session);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }, [sessionId]);

  const links = [
    { path: "/myprofile", label: "My Profile" },
    { path: "/myclasses", label: "My Classes" },
  ];

  const handleSave = async () => {
    const rate = {
      sStars,
      rStars,
      tStars,
      feedSession,
      feedTeacher,
      feedRoom,
      profileId: userStore.user?.id,
      sessionId,
      roomId: session?.sessionBooking[0]?.bookingRoom?.id,
    };

    try {
      await rateSession(rate);
      navigate("/myclasses");
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Navbar />

      <div className="rate-page-container">
        <div className="links">
          {links.map((link, index) => (
            <div className="link" key={index}>
              <Link to={link.path} className="label">
                {link.label}
              </Link>
              <div className="arrow">&gt;</div>
            </div>
          ))}
          <div className="active label">Ratings and reviews</div>
        </div>

        <div className="items">
          <RateItem
            type="course"
            session={session}
            setFeedback={setFeedSession}
            setRating={setSStars}
          />
          <hr />
          <RateItem
            type="teacher"
            session={session}
            setFeedback={setFeedTeacher}
            setRating={setTStars}
          />
          <hr />
          <RateItem
            type="classroom"
            session={session}
            setFeedback={setFeedRoom}
            setRating={setRStars}
          />
        </div>

        <div className="buttons">
          <Button
            text="Cancel"
            type="primary"
            customColor="#373737"
            width="400px"
            preventDefault={true}
            onClick={() => navigate(-1)}
          />
          <Button
            text="Save"
            type="primary"
            width="400px"
            preventDefault={true}
            onClick={handleSave}
          />
        </div>
      </div>
    </>
  );
}
