import React, { useEffect } from "react";

import styles from "./item.module.css";

interface IProps {
  question: any;
}

export default function Question({ question }: IProps) {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <div className="w-full p-[14px] sm:px-6 sm:py-5 border-b border-primary-gray-200 ">
      <div
        className="flex items-center gap-3 sm:gap-9 cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <img
          src={
            isExpanded
              ? "/images/icons/minus.svg"
              : "/images/icons/plus-primary.svg"
          }
        />

        <span className="text-body-medium sm:text-body-large font-body-medium-bold sm:font-body-large">
          {question?.title}
        </span>
      </div>

      {isExpanded && question?.content?.length > 0 && (
        <div className="text-body-small sm:text-body-medium font-body-small sm:font-body-medium flex flex-col gap-7  pl-9 pt-[24px] pb-[30px] sm:pl-[60px] sm:pt-[30px] sm:pb-5">
          {question?.content?.map((item: any, index: number) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      )}
    </div>
  );
}
