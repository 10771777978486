import { useEffect, useState } from "react";
import { observer } from "mobx-react";

import styles from "./form.module.css";
import { useStore } from "../../../../store";

import { loadStripe } from "@stripe/stripe-js";

import env from "../../../../config/env";

const stripePromise = loadStripe(env.REACT_APP_STRIPE_PUBLIC_KEY);

interface Location {
  countryName?: string;
}

const PaymentMethods = observer(
  ({
    paymentMethod,
    setPaymentMethod,
  }: {
    paymentMethod:
      | "PostWire"
      | "Onsite"
      | "OnlinePayment"
      | "KonnectPayment"
      | "StripePayment";
    setPaymentMethod: React.Dispatch<
      React.SetStateAction<
        | "PostWire"
        | "Onsite"
        | "OnlinePayment"
        | "KonnectPayment"
        | "StripePayment"
      >
    >;
  }) => {
    const { userStore } = useStore();
    const { paymentStore, courseStore } = useStore();
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [location, setLocation] = useState<Location | null>(null);

    useEffect(() => {
      // const storedLocation = { countryName: "France" };
      const storedLocation = localStorage.getItem("userLocation");
      if (storedLocation) {
        const parsedLocation = JSON.parse(storedLocation);
        setLocation(parsedLocation);
        console.log("Country Name:", parsedLocation.countryName);

        // Check if the user is outside Tunisia and redirect them to the checkout session
        if (parsedLocation.countryName !== "Tunisia") {
          (async () => {
            try {
              setIsProcessing(true);
              await paymentStore.createCheckoutSession(
                courseStore.courseDetails?.price,
                "usd",
                courseStore.courseDetails?.id
              );
            } catch (err) {
              console.error("Error during Payment:", err);
              setError("Failed to initiate payment. Please try again.");
            } finally {
              setIsProcessing(false);
            }
          })();
        }
      }

      userStore.paymentMethodHandler(paymentMethod);
    }, [paymentMethod]);

    // New function to handle Onsite or Wiring payment
    const handleWiringOrOnsitePayment = async () => {
      setIsProcessing(true);
      setError(null);
      try {
        const response = await paymentStore.createWiringOrOnsitePaymentSession(
          courseStore.courseDetails?.id,
          paymentMethod as "PostWire" | "Onsite"
        );
        // alert(response.data.message);
      } catch (err) {
        console.error("Error during Onsite/Wiring Payment:", err);
        setError("Failed to initiate payment. Please try again.");
      } finally {
        setIsProcessing(false);
      }
    };

    const handlePayment = async () => {
      if (location?.countryName !== "Tunisia") return; // Prevent unnecessary trigger for non-Tunisian users
      setIsProcessing(true);
      setError(null);
      try {
        if (
          paymentMethod === "StripePayment" ||
          paymentMethod === "KonnectPayment"
        ) {
          await paymentStore.createCheckoutSession(
            courseStore.courseDetails?.price,
            "usd",
            courseStore.courseDetails?.id
          );
        } else if (paymentMethod === "Onsite" || paymentMethod === "PostWire") {
          await handleWiringOrOnsitePayment();
        }
      } catch (err) {
        console.error("Error during Payment:", err);
        setError("Failed to initiate payment. Please try again.");
      } finally {
        setIsProcessing(false);
      }
    };

    const isUserFromTunisia =
      location?.countryName === "Tunisia" ||
      userStore.user?.country?.toLowerCase().includes("tunisia");

    return (
      <div className={styles.payment_methods}>
        <h4>Payment Methods</h4>

        {isProcessing && <p>Redirecting to payment...</p>}

        {!isProcessing && (
          <div className={styles.payment_methods_container}>
            {isUserFromTunisia ? (
              // Tunisian Users' Payment Options
              <>
                <div
                  className={styles.payment_method}
                  onClick={() => setPaymentMethod("Onsite")}
                >
                  <div className={styles.payment_method_name}>
                    <input
                      id="Onsite"
                      type="radio"
                      name="payment_method"
                      value={paymentMethod}
                      checked={paymentMethod === "Onsite"}
                    />
                    <label htmlFor="Onsite">Onsite</label>
                  </div>
                  <img
                    src="/images/icons/payment-onsite.svg"
                    alt="Onsite Payment"
                  />
                </div>

                <div
                  className={styles.payment_method}
                  onClick={() => setPaymentMethod("PostWire")}
                >
                  <div className={styles.payment_method_name}>
                    <input
                      id="PostWire"
                      type="radio"
                      name="payment_method"
                      checked={paymentMethod === "PostWire"}
                    />
                    <label htmlFor="PostWire">Wiring</label>
                  </div>
                  <img
                    src="/images/icons/payment-post.svg"
                    alt="Wiring Payment"
                  />
                </div>

                <div
                  className={styles.payment_method}
                  onClick={() => setPaymentMethod("KonnectPayment")}
                >
                  <div className={styles.payment_method_name}>
                    <input
                      id="KonnectPayment"
                      type="radio"
                      name="payment_method"
                      checked={paymentMethod === "KonnectPayment"}
                    />
                    <label htmlFor="KonnectPayment">Konnect</label>
                  </div>
                  <img
                    src="/images/konnect-logo.png"
                    alt="Konnect Payment"
                    height="90rem"
                    width="90rem"
                  />
                </div>
              </>
            ) : (
              // Non-Tunisian Users - Automatically Redirected
              <p>Redirecting to Stripe Checkout...</p>
            )}
          </div>
        )}

        {error && <p className={styles.error_message}>{error}</p>}

        <div className={styles.payment_methods_security}>
          <img src="/images/icons/lock.svg" width={28} height={28} alt="lock" />
          <span>
            We protect your payment information using encryption to provide
            bank-level security.
          </span>
        </div>
      </div>
    );
  }
);

export default PaymentMethods;
