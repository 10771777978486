import React from "react";
import { observer } from "mobx-react";

import styles from "./instructors.module.css";
import InstructorItem from "../Item";
import { useStore } from "../../../../../store";

const Instructors: React.FC = () => {
    const { courseStore } = useStore();
    
    return (
        <div className={styles.instructors_container}>
            <div className={styles.instructors}>
                <div className={styles.instructors_heading}>
                    <span>instructors</span>
                    <h2>Discover our Instructors</h2>
                    <p>On Ed-Circle, instructors from all over the country instruct thousands of students. We offer the knowledge and abilities.</p>
                </div>

                <div className={styles.instructors_list}>
                    {courseStore.instructors?.map((instructor: any, index) => (
                        <InstructorItem
                            key={index}
                            name={instructor?.fullName}
                            job={instructor?.profession}
                            description={instructor?.description}
                            picture={instructor?.image?.name}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default observer(Instructors);