import { observer } from "mobx-react";
import styles from "./stories.module.css";
import { useStore } from "../../../../store";
import env from "../../../../config/env";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import Container from "src/components/shared/Container";

function CourseStories({ type }: { type: "course" | "class" }) {
  const { courseStore } = useStore();
  const { t } = useTranslation();

  let course: any = courseStore.courseDetails;
  if (type === "class") {
    course = course?.sessionCourse;
  }
  const stories: any = course?.testimonials || [];

  const settings = {
    dots: true,
    infinite: stories?.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  if (stories?.length === 0) return null;

  return (
    <div className={styles.course_stories} id="success-stories">
      <Container>
        <Slider {...settings}>
          {stories?.map((story: any, index: number) => (
            <div
              className={styles.course_stories_content}
              key={story?.author || index}
            >
              <div className={styles.course_stories_info}>
                <h4 className={styles.course_stories_subtitle}>
                  {" "}
                  {t("buttons.successStories")}{" "}
                </h4>
                <h3 className={styles.course_stories_title}>
                  {" "}
                  {t("messages.excellentCourse")}{" "}
                </h3>
                <p className={styles.course_stories_description}>
                  {story?.content || ""}
                </p>
                {/* </div>

            <div className={styles.course_stories_author}> */}
                <img
                  alt="Reviewer 1"
                  src={
                    story?.photo?.container?.name
                      ? `${env.IMAGE_API}${story?.photo?.container?.name}`
                      : "/images/user-default.png"
                  }
                  className={styles.course_stories_author_image}
                />
                <div>
                  <h4 className={styles.course_stories_author_name}>
                    {story?.author || ""}
                  </h4>
                  {/* <p className={styles.course_stories_author_position}>Web Developer at Wecode</p> */}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </Container>
    </div>
  );
}

export default observer(CourseStories);
