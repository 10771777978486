import React from "react";

import styles from "./instructor.module.css";
import env from "../../../../../config/env";

interface IProps {
    name: string;
    job: string;
    description: string;
    picture: string;
}

const InstructorItem: React.FC<IProps> = ({
    name,
    job,
    description,
    picture,
}) => {
    return (
        <div className={styles.instructor}>
            <img
                alt="instructor"
                loading="lazy"
                src={`${env.IMAGE_API}${picture}`}
                height={80}
                width={80}
                className={styles.instructor_picture}
            />
            
            <div className={styles.instructor_details}>
                <span className={styles.instructor_name}>{name}</span>
                <span className={styles.instructor_job}>{job}</span>
                <span className={styles.instructor_description}>{description}</span>
            </div>

            <div className={styles.instructor_social}>
                <a href="#" target="_blank">
                    <img
                        src="/images/icons/twitter.svg"
                        alt="Facebook"
                        width={24}
                        height={24}
                    />
                </a>
                <a href="#" target="_blank">
                    <img
                        src="/images/icons/linkedin.svg"
                        alt="Facebook"
                        width={24}
                        height={24}
                    />
                </a>
            </div>
        </div>
    );
};

export default InstructorItem;
