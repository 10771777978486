import React from "react";

import styles from "./blogs.module.css";
import Blog from "../BlogItem";
import { Button } from "src/components/shared";

const Blogs: React.FC = () => {
  const blogs = [
    {
      title:
        "Cyber Security certifications approved by the US Department of Defense",
      description:
        "We’re thrilled to announce a groundbreaking development in our partnership with CertNexus!",
      date: "May 23, 2023",
      image:
        "https://wemag.wecode.land/wp-content/uploads/2024/01/Bannie%CC%80re-de-blog-6-2048x1152.png",
      link: "https://wemag.wecode.land/cyber-security-certifications-by-certnexus-approved-by-the-us-defense-department",
      tags: ["Research", "Fun Facts"],
    },
    {
      title: "Teach with ClassX: Sharing Your Expertise with the World",
      description:
        "ClassX offers a unique opportunity for tech experts to become instructors and share their expertise globally. Here’s a brief guide on how to join ClassX and make a positive impact on Tech enthusiasts.",
      date: "January 14, 2024",
      image:
        "https://wemag.wecode.land/wp-content/uploads/2024/01/Bannie%CC%80re-de-blog-1024x576.png",
      link: "https://wemag.wecode.land/teach-with-us",
      fill: true,
      tags: ["Research", "Fun Facts"],
    },
    {
      title: "List your Space",
      description:
        "Are you the manager of a coworking space, training facility, or any versatile venue capable of hosting coding courses...",
      date: "August 2, 2023",
      image:
        "https://wemag.wecode.land/wp-content/uploads/2023/08/Banniere-de-blog-1-1024x576.png",
      link: "https://wemag.wecode.land/list-your-space",
      tags: ["Research", "Fun Facts"],
    },
  ];

  return (
    <div className={styles.blogs}>
      <div className="text-body-large font-body-large-bold sm:text-subheading flex justify-between items-center">
        <h2>Our Most Recent Blogs</h2>
        <a
          href="https://blog.classx.tech/"
          target="_blank"
          className="hidden sm:block"
        >
          <Button
            type="outline"
            text="View more Blogs"
            width="fit-content"
            customStyle={{ height: "50px" }}
          />
        </a>
      </div>

      <div className={styles.blogs_list}>
        {blogs.map((blog, index) => (
          <Blog key={index} blog={blog} fill={blog.fill} />
        ))}
      </div>

      <a
        href="https://blog.classx.tech/"
        target="_blank"
        className="block sm:hidden"
      >
        <Button
          type="outline"
          text="View more Blogs"
          width="fit-content"
          customStyle={{ height: "50px" }}
        />
      </a>
    </div>
  );
};

export default Blogs;
