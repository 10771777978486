import React from "react";

import styles from "./faq.module.css";

interface IProps {
    faq: {
        id: string,
        question: string;
        answer: string;
    };
}

export default function FaqItem({ faq }: IProps) {
    const [isExpanded, setIsExpanded] = React.useState(false);

    return (
        <div className={styles.faq}>
            <div className={styles.faq_header} onClick={() => setIsExpanded(!isExpanded)}>
                <span className={styles.faq_header_number}>{faq.id}.</span>
                <span className={styles.faq_header_question}>{faq.question}</span>
                <img
                    width={24}
                    height={24}
                    src={isExpanded ? "/images/icons/minus.svg" : "/images/icons/plus-primary.svg"}
                    alt="Arrow down"
                />
            </div>

            {isExpanded && (
                <div className={styles.faq_body}>
                    <p>{faq.answer}</p>
                </div>
            )}
        </div>
    );
}
