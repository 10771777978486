"use client";
import React from "react";
import { useLocalObservable } from "mobx-react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import type { RootStore } from "./root";
import rootStore from "./root";

const storeContext = React.createContext<RootStore | null>(null);

export const StoreProvider = ({ children }: { children: React.ReactNode }) => {
  const store = useLocalObservable(() => rootStore);
  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
};

export const useStore = () => {
  const store = React.useContext(storeContext);
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error("useStore must be used within a StoreProvider.");
  }
  return store;
};

export const useDeleteStore = () => {
  const location = useLocation();
  const { userStore } = useStore();
  useEffect(() => {
    if (location.pathname !== "/register") {
      userStore.setUserData({});
    }
  }, [location.pathname]);
};
