import "./styles.css";
import React from "react";

interface TablePageProps {
  data: any[] | null;
  title: string;
  columns: string[];
  page: number;
  numberOfPages: number;
  count: number;
  handlePreviousPage: () => void;
  handleNextPage: () => void;
  Rows: React.ComponentType<any>;
  showActionButtons?: boolean
}

export default function Table({ data, title, columns, Rows, page, numberOfPages, count, handleNextPage, handlePreviousPage, showActionButtons }: TablePageProps) {

  return (
    <div className="tab-content">
      <div className="top">
        <h1>{title}</h1>
        <div className="features">
          <div className="icon">
            <img src="/images/icons/sort.svg" alt="" />
            Sort
          </div>
          <div className="icon">
            <img src="/images/icons/filter.svg" alt="" />
            Filter
          </div>
        </div>
      </div>

      <div className="table-container">      
        <table>
          <thead>
            <tr>
              {
                columns.map((column, index) => (
                  <th key={index}>{column}</th>
                ))
              }
            </tr>
          </thead>

          <tbody>
            {
              data && data.length > 0 ?
                  <Rows data={data} tab={title} showActionButtons={showActionButtons} />
              : <tr id="nodata">
                  <td colSpan={columns.length}>
                    <div className="nodata">
                      <img src="/images/icons/nodata.svg" alt="" />
                      <span>Oups! No {title.split(" ")[0]} Found!</span>
                    </div>
                  </td>
                </tr>
            }
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <span>{page} - {numberOfPages} of {count}</span>
        <button onClick={handlePreviousPage}>&lt;</button>
        <button onClick={handleNextPage}>&gt;</button>
      </div>
    </div>
  );
}
