import { useEffect, useState } from "react";
import Table from "../../../components/others/Table";
import { Link, useNavigate } from "react-router-dom";
import { useStore } from "../../../store";
import { Button, Navbar, Loading } from "src/components/shared";
import { changeClassroomPrivacy, getBookingList, getMyList } from "src/store/Classroom";
import ClassroomsList from "./classroomsList";
import RequestRows from "./Rows/requestRows";

export default function ClassroomsManagement() {
    const navigate = useNavigate();
    const { userStore } = useStore();

    useEffect(() => {
        userStore.getUser().then(() => {
            const user = userStore.user;
            if (!user) {
                navigate("/login");
            }
        });
    }, []);
  
    const user = userStore.user;

    const [loading, setLoading] = useState(true);
    const [privacyChanged, setPrivacyChanged] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [classrooms, setClassrooms] = useState<any[] | null>(null);
    const [requests, setRequests] = useState<any[] | null>(null);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const itemsPerPage = activeTab === 0 ? 5 : 6;
    const numberOfPages = count > 0 ? Math.ceil(count / itemsPerPage) : 1;

    useEffect(() => {
        setCount(0);
        setLoading(true);
        
        if (activeTab === 0)
            getMyList(itemsPerPage, itemsPerPage * (page - 1), 1)
            .then((response) => {
                if (response !== null) {
                    console.log("response", response);
                    setClassrooms(Object.entries(response)[0][1] as any[]);
                    setCount(Object.entries(response)[1][1] as number);
                    setLoading(false);
                }
            }).catch((error) => {
                setLoading(false);
                console.log(error);
            });
        else if (activeTab === 1)
            getBookingList(user?.id, itemsPerPage, itemsPerPage * (page - 1), 2)
            .then((response) => {
                if (response !== null) {
                    console.log("response", response);
                    setRequests(Object.entries(response)[0][1] as any[]);
                    setCount(Object.entries(response)[1][1] as number);
                    setLoading(false);
                }
            }).catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }, [page, activeTab, privacyChanged]);    

    const links = [
        { path: "/myprofile", label: "My Profile" },
    ];

    const tabs = [
        {
            name: "My Classrooms",
            title: "My Classrooms",
            columns: [],
            data: classrooms,
            rows: RequestRows as React.ComponentType<any>,
        },
        {
            name: "Hosting Requests",
            title: "Hosting Requests",
            columns: ["Classroom","Requested on", "Tutors", "From-To", "Time", "Places", "Status", "Accept/Refuse"],
            data: requests,
            rows: RequestRows as React.ComponentType<any>
        },
    ]

    const handleTabClick = (tab: number) => {
        setPage(1);
        setActiveTab(tab);
    };

    const handlePreviousPage = () => {
        if (page > 1) {
          setPage(page - 1);
        }
    }
    
    const handleNextPage = () => {
        if (page < numberOfPages) {
            setPage(page + 1);
        }
    }

    const handlePrivacy = (id: string, privacy: boolean) => {
        setLoading(true);
        changeClassroomPrivacy(id, privacy)
            .then((response) => {
                if (response !== null) {
                    setPrivacyChanged(!privacyChanged);
                }
            }).catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }

    if (loading) {
        return (
            <Loading />
        );
    }

    return (
        <>
            <Navbar />

            <div className="table-page-container">
                <div className="links">
                    {links.map((link, index) => (
                        <div className="link" key={index}>
                            <Link to={link.path} className="label">
                                {link.label}
                            </Link>
                            <div className="arrow">&gt;</div>
                        </div>
                    ))}
                    <div className="active label">
                        Classrooms Management
                    </div>
                </div>

                <div className="header">
                    <div className="tabs">
                        {tabs.map((tab, index) => (
                            <div
                                key={index}
                                className={`tab ${activeTab === index ? "active" : ""}`}
                                onClick={() => handleTabClick(index)}
                            >
                                {tab.name}
                            </div>
                        ))}
                    </div>

                    <Button 
                        type="primary"
                        width="170px"
                        text={"Add new classroom"}
                        onClick={() => navigate("/newClassroom")}
                    />
                </div>

                {activeTab === 0 && 
                    <ClassroomsList 
                        data={tabs[activeTab].data} 
                        handlePrivacy={handlePrivacy} 
                        page={page}
                        numberOfPages={numberOfPages}
                        count={count}
                        handleNextPage={handleNextPage}
                        handlePreviousPage={handlePreviousPage}
                    />
                }

                {activeTab === 1 && <Table 
                    title={tabs[activeTab].title}
                    columns={tabs[activeTab].columns}
                    data={tabs[activeTab].data}
                    page={page}
                    numberOfPages={numberOfPages}
                    count={count}
                    handleNextPage={handleNextPage}
                    handlePreviousPage={handlePreviousPage}
                    Rows={tabs[activeTab].rows}
                    showActionButtons={activeTab === 1}
                />}
            </div>
        </>
    );
}