const situations = [
    {
        id: "1",
        label: "Student",
    },
    {
        id: "2",
        label: "Job Seeker",
    },
    {
        id: "3",
        label: "Employee",
    },
    {
        id: "4",
        label: "Entrepreneur",
    },
    {
        id: "5",
        label: "Other",
    }
];

export default situations;