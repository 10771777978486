import { observer } from "mobx-react";

import styles from "./questions.module.css";
import Question from "./Question";

function Questions() {
  const questions = [
    {
      title: "Who can sign up as an instructor?",
      content: [
        <p>
          Anyone with expertise in a subject, relevant qualifications, strong
          communication skills, and a commitment to teaching can apply to become
          an instructor on ClassX. To get started, apply through our{" "}
          <a
            href="https://community.classx.tech/teach-with-us/"
            className="text-primary-purple-400 underline cursor-pointer"
            target="_blank"
          >
            Instructor Signup Page
          </a>
          .
        </p>,
      ],
    },
    {
      title: "Who can sign up as a host?",
      content: [
        <p>
          <b>
            Anyone with a suitable venue and a commitment to supporting learning
            can sign up as a host on ClassX.
          </b>{" "}
          If you have space to accommodate in-person classes and want to
          facilitate education in your community, apply through our{" "}
          <a
            href="https://community.classx.tech/list-your-space/"
            className="text-primary-purple-400 underline cursor-pointer"
            target="_blank"
          >
            Host Signup Page.
          </a>
          .
        </p>,
      ],
    },
    {
      title: "How do I enroll in a Class?",
      content: [
        <span>
          1. Create an Account: Sign up on{" "}
          <a
            href="/"
            className="text-primary-purple-400 underline cursor-pointer"
          >
            ClassX.com
          </a>
          .
        </span>,
        <span>
          2. Browse Classes: Find a topic that interests you in the location of
          your choice..
        </span>,
        <span>
          3. Select and Enroll: Click "Reserve a seat" and complete the payment.
        </span>,
        <span>
          4. Start Learning: Show up on the first day of class, connect with
          peers, and learn.
        </span>,
      ],
    },
    {
      title: "Can I cancel or reschedule a course?",
      content: [
        <span>Yes:</span>,
        <ul className="list-disc">
          <li className="ml-5">
            Cancel: Full refund within 24 hours of enrollment. Partial refunds
            before the start date.
          </li>
          <li className="ml-5">
            Reschedule: Contact support or your instructor to adjust your
            schedule based on availability.
          </li>
        </ul>,
      ],
    },
    {
      title: "Are all courses in-person or are there online options?",
      content: [
        <p>
          ClassX offers in-person classes. Other formats that best suit your
          lifestyle and learning style when selecting a course like onlive live
          learning may be available.
        </p>,
      ],
    },
    {
      title: "How can I contact support if I need help?",
      content: [
        <p>You can reach ClassX support through:</p>,
        <ul className="list-disc">
          <li className="ml-5">
            Email:{" "}
            <a
              href="mailto: hello@classx.tech"
              className="text-primary-purple-400 underline cursor-pointer"
            >
              hello@classx.tech
            </a>
          </li>
          <li className="ml-5">
            Phone:{" "}
            <a
              href="tel: +1 (408) 569 7534"
              className="text-primary-purple-400 underline cursor-pointer"
            >
              +1 (408) 569 7534
            </a>{" "}
            (Mon-Fri, 9 AM - 5 PM EST)
          </li>
        </ul>,
      ],
    },
  ];

  return (
    <div className={styles.questions_content_content}>
      <div className="flex flex-col items-center gap-1.5 w-full text-center">
        <h4 className="text-body-large sm:text-heading lg:text-heading-medium font-body-large-bold sm:font-heading lg:font-heading-medium">
          <span className={styles.colored_span}>Questions?</span> Look here!
        </h4>
        <h3 className="text-body-medium sm:text-body-large font-body-medium sm:font-body-large">
          Some frequently asked questions answered for you
        </h3>
      </div>

      <div className={styles.questions_content_items}>
        {questions?.map((item: any, index: number) => (
          <Question key={index} question={item} />
        ))}
      </div>
    </div>
  );
}

export default observer(Questions);
