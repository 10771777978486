import { useState } from "react";
import styles from "./goals.module.css";
import { Button, Popup } from "../../../../components";
import { observer } from "mobx-react";
import { useStore } from "../../../../store";
import env from "../../../../config/env";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


interface IProps {
  type: "course" | "class";
}

function AboutInstructor({ type }: IProps) {
  const navigate = useNavigate();
  const { courseStore, userStore } = useStore();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const course: any = courseStore.courseDetails;
  const objectifs: any =
    type === "course" ? course?.objectifs : course?.sessionCourse?.objectifs;
  const perspectives: any =
    type === "course"
      ? course?.perspectives
      : course?.sessionCourse?.perspectives;

  const participate = async () => {
    if (userStore.user) {
      courseStore.selectCourse(course.id);
      navigate(`/payment/${course.id}`);
    } else {
      setIsOpen(true);
    }
  };

  const renderContent = (content: any) => {
    if (!content?.blocks || content.blocks.length === 0) return null;
  
    let currentListType: string | null = null;
    const elements: JSX.Element[] = [];
    let currentList: JSX.Element[] = [];
  
    content.blocks.forEach((block: any, index: number) => {
      const { text, type, inlineStyleRanges } = block;
  
      let styledText = text;
      if (inlineStyleRanges?.length > 0) {
        inlineStyleRanges.forEach((styleRange: any) => {
          const { style, offset, length } = styleRange;
          const styleText = styledText.slice(offset, offset + length);
  
          if (style === "BOLD") {
            styledText = styledText.replace(
              styleText,
              `<strong>${styleText}</strong>`
            );
          } else if (style === "ITALIC") {
            styledText = styledText.replace(
              styleText,
              `<em>${styleText}</em>`
            );
          } else if (style === "UNDERLINE") {
            styledText = styledText.replace(
              styleText,
              `<u>${styleText}</u>`
            );
          }
        });
      }
  
      if (type === "ordered-list-item" || type === "unordered-list-item") {
        if (currentListType !== type) {
          if (currentList.length > 0) {
            elements.push(
              currentListType === "ordered-list-item" ? (
                <ol className="list-decimal ml-5 text-[#1C1D1F] font-inter text-[17px]">
                  {currentList}
                </ol>
              ) : (
                <ul className="list-disc ml-5 text-[#1C1D1F] font-inter text-[17px]">
                  {currentList}
                </ul>
              )
            );
            currentList = [];
          }
          currentListType = type;
        }
        currentList.push(
          <li
            key={index}
            dangerouslySetInnerHTML={{ __html: styledText }}
          />
        );
      } else {
        if (currentList.length > 0) {
          elements.push(
            currentListType === "ordered-list-item" ? (
              <ol className="list-decimal ml-5 text-[#1C1D1F] font-inter text-[17px]">
                {currentList}
              </ol>
            ) : (
              <ul className="list-disc ml-5 text-[#1C1D1F] font-inter text-[17px]">
                {currentList}
              </ul>
            )
          );
          currentList = [];
        }
        currentListType = null;
  
        elements.push(
          <p
            key={index}
            className="text-[#1C1D1F] font-inter text-[17px] mb-2"
            dangerouslySetInnerHTML={{ __html: styledText }}
          />
        );
      }
    });
  
    if (currentList.length > 0) {
      elements.push(
        currentListType === "ordered-list-item" ? (
          <ol className="list-decimal ml-5 text-[#1C1D1F] font-inter text-[17px]">
            {currentList}
          </ol>
        ) : (
          <ul className="list-disc ml-5 text-[#1C1D1F] font-inter text-[17px]">
            {currentList}
          </ul>
        )
      );
    }
  
    return elements;
  };
  
  
  // const renderContent = (content: any) => {
  //   if (!content?.blocks || content.blocks.length === 0) return null;
  
  //   const applyStyles = (text: string, inlineStyleRanges: any[]) => {
  //     let styledText = [];
  //     let lastIndex = 0;
  
  //     inlineStyleRanges.forEach((range: any, index: number) => {
  //       const { style, offset, length } = range;
  //       const currentText = text.slice(offset, offset + length);
  
  //       if (offset > lastIndex) {
  //         styledText.push(<span key={`plain-${lastIndex}`}>{text.slice(lastIndex, offset)}</span>);
  //       }
  
  //       const styleObject: React.CSSProperties = {};
  //       if (style.startsWith("fontsize-")) {
  //         const fontSize = style.split("-")[1];
  //         styleObject.fontSize = `${fontSize}px`;
  //       }
  //       if (style.startsWith("color-")) {
  //         const color = style.split("-")[1];
  //         styleObject.color = color;
  //       }
  //       if (style.startsWith("bgcolor-")) {
  //         const bgColor = style.split("-")[1];
  //         styleObject.backgroundColor = bgColor;
  //       }
  //       if (style === "BOLD") {
  //         styleObject.fontWeight = "bold";
  //       }
  //       if (style === "ITALIC") {
  //         styleObject.fontStyle = "italic";
  //       }
  //       if (style === "UNDERLINE") {
  //         styleObject.textDecoration = "underline";
  //       }
  
  //       styledText.push(
  //         <span key={`styled-${index}`} style={styleObject}>
  //           {currentText}
  //         </span>
  //       );
  
  //       lastIndex = offset + length;
  //     });
  
  //     if (lastIndex < text.length) {
  //       styledText.push(<span key={`plain-${lastIndex}`}>{text.slice(lastIndex)}</span>);
  //     }
  //     console.log("styledText ",styledText )
  //     return styledText;
  //   };
  
  //   return content.blocks.map((block: any, index: number) => {
  //     console.log("content.blocks",content.blocks)
  //     const styledContent = applyStyles(block.text, block.inlineStyleRanges);
  
  //     if (block.type === "ordered-list-item") {
  //       return (
  //         <li key={`block-${index}`} style={{ listStyleType: "decimal", marginLeft: "20px" }}>
  //           {styledContent}
  //         </li>
  //       );
  //     } else if (block.type === "unstyled") {
  //       return (
  //         <p key={`block-${index}`} style={{ margin: "10px 0" }}>
  //           {styledContent}
  //         </p>
  //       );
  //     } else {
  //       return null;
  //     }
  //   });
  // };
  
  
  
  

  return (
    <div className={styles.course_goals} id="goals-prospects">
      <div className={styles.course_goals_content}>
        {objectifs &&
        objectifs?.content &&
        objectifs?.content?.blocks &&
        objectifs?.content?.blocks.length > 0 &&
        objectifs?.image?.name ? (
          <div className={styles.course_goals_item}>
            <div className={styles.course_goals_details}>
              {/* <h3>{objectifs?.content?.blocks[0]?.text}</h3> */}
              <p className="text-[#4B444D] font-inter font-bold text-3xl">
                {t("messages.goals")}
              </p>
              {/* <div className="text-[#1C1D1F] font-intet text-[17px]">
                <p>{objectifs?.content?.blocks[0]?.text}</p>
                <p>{objectifs?.content?.blocks[1]?.text}</p>
              </div> */}
            <div>{renderContent(objectifs.content)}</div>
              {type === "class" && (
                <div className="">
                  <Button
                    type="primary"
                    text={t("buttons.startNow")}
                    onClick={() => participate()}
                  />
                </div>
              )}
            </div>

            <img
            className={styles.image} 
              width={519}
              height={360}
              alt="goals"
              src={`${env.IMAGE_API}${objectifs?.image?.name}`}
            />
          </div>
        ) : null}

        {perspectives &&
        perspectives?.content &&
        perspectives?.content?.blocks &&
        perspectives?.content?.blocks.length > 0 &&
        perspectives?.content?.blocks.length > 0 &&
        perspectives?.image?.name ? (
          <div className={styles.course_goals_item}>
            <img
            className={styles.image} 
              width={519}
              height={360}
              alt="goals"
              src={`${env.IMAGE_API}${perspectives.image.name}`}
            />

            <div className={styles.course_goals_details}>
              {/* <h3>{perspectives?.content?.blocks[0]?.text}</h3> */}
              <p className="text-[#4B444D] font-inter font-bold text-3xl">
                {t("messages.perspectives")}
              </p>
              {/* <p>{perspectives?.content?.blocks[0]?.text}</p> */}
              <div>{renderContent(perspectives.content)}</div>
              {/* <p>{block?.text}</p> */}
              {type === "class" && (
                <div >
                  <Button
                    type="primary"
                    text={t("buttons.startNow")}
                    onClick={() => participate()}
                  />
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>

      <Popup
        type="course"
        isOpen={isOpen}
        course={course.id}
        closeModal={() => setIsOpen(false)}
      />
    </div>
  );
}

export default observer(AboutInstructor);
