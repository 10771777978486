import React from "react";
import styles from "./loading.module.css";
import ClipLoader from "react-spinners/ClipLoader";

const Loading: React.FC = () => {
  return (
    <div className={styles.loading_spinner}>
      <ClipLoader
        color={"#CA2D5D"}
        size={100}
      />
    </div>
  );
};

export default Loading;
