import { acceptRefuseHosting } from "src/store/Classroom";

const RequestRows: React.FC<{ data: any[]; showActionButtons?: boolean }> = ({
  data,
  showActionButtons,
}) => {
  const handleDate = (date: Date) => {
    return date ? date.toString().substring(0, 10).replace(/-/g, "/") : "";
  };

  const handleStatus = (status: number) => {
    switch (status) {
      case 1:
        return <div style={{ color: "rgb(0, 191, 165)" }}>Accepted</div>;
      case 2:
        return <div style={{ color: "rgb(230, 65, 61)" }}>Rejected</div>;
      default:
        return <div style={{ color: "rgb(255, 138, 101)" }}>Pending</div>;
    }
  };

  // Accept/Refuse Button Handlers
  const handleAccept = async (request: any) => {
    try {
      const response = await acceptRefuseHosting(
        request.sessionId,
        request.roomId,
        true
      );
      if(response){
        window.location.reload();
      }
    } catch (error) {
      console.log("error");
    }
  };

  const handleRefuse = async (request: any) => {
    try {
      const response = await acceptRefuseHosting(
        request.sessionId,
        request.roomId,
        false
      );
      if(response){
        window.location.reload();
      }
    } catch (error) {
      console.log("error");
    }
  };

  return (
    <>
      {data.map((request, index) => (
        <tr key={index}>
          <td className="name">{request?.bookingRoom?.name}</td>
          <td>{handleDate(request?.createdAt)}</td>
          <td>{request.bookingSession?.tutors?.fullName ?? ""}</td>
          <td>
            {handleDate(request?.bookingSession?.from)} <br />{" "}
            {handleDate(request?.bookingSession?.to)}
          </td>
          <td>{request?.time}</td>
          <td>{request?.places}</td>
          <td className="status">{handleStatus(request?.status)}</td>

          {(showActionButtons && request.status === 0) && (
            <td>
              <div className="w-full flex justify-start items-center space-x-6 mx-8">
                <button onClick={() => handleAccept(request)}>
                  <img
                    src="images/icons/accept.png"
                    height="20px"
                    width="20px"
                  ></img>
                </button>
                <button onClick={() => handleRefuse(request)}>
                  <img
                    src="images/icons/refuse.png"
                    height="15px"
                    width="15px"
                  ></img>
                </button>
              </div>
            </td>
          )}
        </tr>
      ))}
    </>
  );
};

export default RequestRows;
