import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react";
import { useStore } from "../../../store";
import { useNavigate, useLocation } from "react-router-dom";
import { Navbar } from "src/components/shared";
import { observable } from "mobx";
import Container from "src/components/shared/Container";
import ResetPasswordSuccess from "../ResetPasswordSuccess";

function ResetPassword1() {
  const { state } = useLocation();
  const email = state?.email;
  const code = state?.code;

  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isPasswordTouched, setIsPasswordTouched] = useState(false);
  const [isConfirmPasswordTouched, setIsConfirmPasswordTouched] =
    useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { userStore } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!code) {
      navigate("forgot-password", { replace: true });
    }
    validateInputs();
  }, [password, confirmPassword, code]);

  const validateInputs = () => {
    let valid = true;

    // Password validation
    if (!password) {
      setPasswordError("Password is required.");
      valid = false;
    } else if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters.");
      valid = false;
    } else {
      setPasswordError("");
    }

    if (!confirmPassword) {
      setConfirmPasswordError("Please confirm your password.");
      valid = false;
    } else if (confirmPassword !== password) {
      setConfirmPasswordError("Passwords do not match.");
      valid = false;
    } else {
      setConfirmPasswordError("");
    }

    setIsButtonDisabled(!valid);
    return valid;
  };

  const handleConfirmPasswordChange = (value: string) => {
    setConfirmPassword(value);
    validateInputs();
  };

  const handleSubmit = async () => {
    if (!validateInputs() || !email || !code) return;

    try {
      const res = await userStore.forgotPasswordWithCode(email, code, password);
      if (res) {
        toast.success("Password reset successfully!");
        setIsSuccess(true);
        // navigate("/success-reset-password");
      } else {
        toast.error("Failed to reset password.");
      }
    } catch (error) {
      toast.error("Error resetting password.");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const VisibleIcon = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3162 13.3078C16.9816 5.89741 7.01879 5.89741 3.68413 13.3078C3.51415 13.6855 3.07014 13.8539 2.69241 13.6839C2.31468 13.514 2.14627 13.07 2.31624 12.6922C6.18158 4.10259 17.8188 4.10259 21.6841 12.6922C21.8541 13.07 21.6857 13.514 21.308 13.6839C20.9302 13.8539 20.4862 13.6855 20.3162 13.3078Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 14C15.75 11.9289 14.0711 10.25 12 10.25C9.92893 10.25 8.25 11.9289 8.25 14C8.25 16.0711 9.92893 17.75 12 17.75C14.0711 17.75 15.75 16.0711 15.75 14Z"
        fill="black"
      />
    </svg>
  );

  const InvisibleIcon = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5001 16L17.0249 12.6038"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17.5V14"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 16L6.96895 12.6124"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 8C6.6 16 17.4 16 21 8"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <>
      <Navbar />
      <ToastContainer theme="dark" />
      <Container>
        {isSuccess ? (
          <div className="flex items-center justify-center pt-5 px-2 bg">
          <ResetPasswordSuccess />
        </div>
        ) : (
          <div className="flex items-center justify-center pt-5 px-2 bg">
            <div className="flex flex-col items-center w-full sm:max-w-md lg:max-w-lg">
              {/* Stepper */}
              <ol className="flex justify-between items-center w-full mb-6 px-1">
                <li className="flex-1 h-[5px] mx-1 bg-[#E83C70] rounded-full"></li>
                <li className="flex-1 h-[5px] mx-1 bg-[#E83C70] rounded-full"></li>
                <li className="flex-1 h-[5px] mx-1 bg-[#E83C70] rounded-full"></li>
              </ol>

              <div className="bg-white rounded-xl border border-[#0000000D] w-full p-4 sm:p-8 md:p-6 lg:p-10">
                <h4 className="text-[22px] font-semibold leading-[32px] mb-2 text-primary-paradise-pink-850 sm:text-base ">
                  Set New Password
                </h4>
                <p className="text-base leading-[25px] font-normal mb-6  text-primary-paradise-pink-850 sm:text-sm">
                  Must be at least 8 characters
                </p>

                <label
                  htmlFor="password"
                  className="mb-2 text-xs leading-[24px] font-medium text-start text-gray-800"
                >
                  Password <span className="text-[#D22121]">*</span>
                </label>
                <div className="relative mb-3">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter a password"
                    onBlur={() => setIsPasswordTouched(true)}
                    className={`flex items-center w-full px-5 py-4 text-sm font-medium outline-none ${
                      isPasswordTouched && passwordError
                        ? "border border-[#D22121]"
                        : "border border-gray-200"
                    } placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl`}
                  />
                  {isPasswordTouched && passwordError && (
                    <span className="absolute right-10 top-1/2 transform pr-1 -translate-y-1/2 text-[#D22121]">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.0419922 8.99999C0.0419922 4.05244 4.05277 0.0416565 9.00033 0.0416565C13.9479 0.0416565 17.9587 4.05244 17.9587 8.99999C17.9587 13.9475 13.9479 17.9583 9.00033 17.9583C4.05277 17.9583 0.0419922 13.9475 0.0419922 8.99999ZM9.00033 4.20832C9.3455 4.20832 9.62533 4.48815 9.62533 4.83332V9.83332C9.62533 10.1785 9.3455 10.4583 9.00033 10.4583C8.65515 10.4583 8.37533 10.1785 8.37533 9.83332V4.83332C8.37533 4.48815 8.65515 4.20832 9.00033 4.20832ZM9.47322 13.584C9.70413 13.3274 9.68334 12.9322 9.42677 12.7013C9.1702 12.4704 8.77502 12.4912 8.54411 12.7477L8.53577 12.757C8.30486 13.0136 8.32566 13.4088 8.58223 13.6397C8.8388 13.8706 9.23398 13.8498 9.46489 13.5932L9.47322 13.584Z"
                          fill="#D22121"
                        />
                      </svg>
                    </span>
                  )}
                  <span
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 pr-1 cursor-pointer"
                    onClick={togglePasswordVisibility}
                    style={{ width: "24px", height: "24px" }}
                  >
                    {showPassword ? <InvisibleIcon /> : <VisibleIcon />}
                  </span>
                </div>

                {isPasswordTouched && passwordError && (
                  <span className="text-[#D22121] text-xs leading-6 font-medium mb-1 text-left block ">
                    {passwordError}
                  </span>
                )}

                <label
                  htmlFor="confirmPassword"
                  className=" text-xs leading-[24px] font-medium text-start text-gray-800"
                >
                  Confirm Password <span className="text-[#D22121] text-xs leading-6 font-medium">*</span>
                </label>
                <div className="relative mb-3">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    onBlur={() => setIsConfirmPasswordTouched(true)}
                    onChange={(e) =>
                      handleConfirmPasswordChange(e.target.value)
                    }
                    placeholder="Confirm your password"
                    className={`w-full px-5 py-4 text-sm font-medium outline-none ${
                      isConfirmPasswordTouched && confirmPasswordError
                        ? "border border-[#D22121]"
                        : "border border-gray-200"
                    } bg-grey-200 text-dark-grey-900 rounded-2xl`}
                  />
                  <span
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? <InvisibleIcon /> : <VisibleIcon />}
                  </span>
                  {isConfirmPasswordTouched && confirmPasswordError && (
                    <span className="absolute right-10 top-1/2 transform -translate-y-1/2 text-[#D22121]">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.0419922 8.99999C0.0419922 4.05244 4.05277 0.0416565 9.00033 0.0416565C13.9479 0.0416565 17.9587 4.05244 17.9587 8.99999C17.9587 13.9475 13.9479 17.9583 9.00033 17.9583C4.05277 17.9583 0.0419922 13.9475 0.0419922 8.99999ZM9.00033 4.20832C9.3455 4.20832 9.62533 4.48815 9.62533 4.83332V9.83332C9.62533 10.1785 9.3455 10.4583 9.00033 10.4583C8.65515 10.4583 8.37533 10.1785 8.37533 9.83332V4.83332C8.37533 4.48815 8.65515 4.20832 9.00033 4.20832ZM9.47322 13.584C9.70413 13.3274 9.68334 12.9322 9.42677 12.7013C9.1702 12.4704 8.77502 12.4912 8.54411 12.7477L8.53577 12.757C8.30486 13.0136 8.32566 13.4088 8.58223 13.6397C8.8388 13.8706 9.23398 13.8498 9.46489 13.5932L9.47322 13.584Z"
                          fill="#D22121"
                        />
                      </svg>
                    </span>
                  )}
                </div>
                {isConfirmPasswordTouched && confirmPasswordError && (
                  <span className="text-[#D22121] text-xs leading-6 font-medium mb-3 text-left block">
                    {confirmPasswordError}
                  </span>
                )}
                <button
                  type="button"
                  onClick={handleSubmit}
                  disabled={isButtonDisabled}
                  className={` text-[#FFFAFB] h-12 min-h-12 justify-center w-full bg-[#E83C70]  border-2 border-transparent hover:border-[#E83C70] hover:text-[#E83C70] hover:bg-white focus:ring-4 focus:outline-none focus:ring-[#FFFFFF]/50 font-semibold rounded-xl leading-[26px] text-sm px-5 text-center inline-flex items-center dark:focus:ring-[#FFFFFF]/55 me-2 mt-0 ${
                    isButtonDisabled
                      ? "bg-[#E83C70] opacity-50 cursor-not-allowed"
                      : "bg-[#E83C70] hover:bg-pink-600"
                  } text-base sm:text-sm md:text-base lg:text-lg`}
                >
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        )}
      </Container>
    </>
  );
}

export default observer(ResetPassword1);
