import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { format } from "date-fns";

import styles from "./UpcomingCohorts.module.css";
import { Button } from "src/components/shared";
import { useStore } from "../../../../store";
import { useNavigate } from "react-router-dom";
import env from "../../../../config/env";
import { formatCurrency } from "../../../../utils/formatCurrency";

interface Props {
  page: "home" | "search";
}

const UpcomingCohorts: React.FC<Props> = ({ page }) => {
  const navigate = useNavigate();
  const { courseStore, userStore } = useStore();
  const searchStorage = JSON.parse(sessionStorage.getItem("search") || "{}");

  const [selectedDomain, setSelectedDomain] = useState<any>({});
  const [selectedCourse, setSelectedCourse] = useState<any>(0);
  const [courses, setCourses] = useState<any[]>([]);

  const loadUpcomingCohorts = async () => {
    try {
      // await courseStore.getUpcomingCohorts(selecetedDomain);
      // setCourses(courseStore.searchedCourses);
      // setSelectedCourse(courseStore.searchedCourses[0]);
      await courseStore.getUpcomingSessions();
      setCourses(courseStore.upcomingSessions);
      setSelectedCourse(0);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadUpcomingCohorts();
  }, []);

  const filter = courseStore.filter;

  const domains = courseStore.domains.map((domain: any) => {
    return { id: domain.id, name: domain.name };
  });
  const starts = courses.length
    ? new Array(Math.ceil(courses[selectedCourse]?.rating)).fill(null)
    : [];

  const rating = courses[selectedCourse]?.rating || 0;
  const totalStars = 5;
  const filledStars = Math.floor(rating);
  const emptyStars = totalStars - filledStars;

  const stars = [
    ...new Array(filledStars).fill("filled"),
    ...new Array(emptyStars).fill("empty"),
  ];

  const formatDate = (date: string | undefined) => {
    if (!date) return "";
    const formattedDate = format(new Date(date), "dd MMMM");
    const [day, month] = formattedDate.split(" ");
    const shortMonth = month.slice(0, 3).toUpperCase();
    return `${day} ${shortMonth}`;
  };

  const handleCourseSelection = (index: number) => {
    setSelectedCourse(index);
    if (window.innerWidth <= 640) {
      navigate("/class/" + courses[index].id);
    }
  };

  const handlePayment = () => {
    navigate("/payment/" + courses[selectedCourse].id);
  };

  const handleMore = () => {
    navigate("/class/" + courses[selectedCourse].id);
  };

  const filterByDomain = (newSelectedDomain: any) => {
    if (newSelectedDomain.name === selectedDomain.name) {
      setSelectedDomain({});
      setCourses(courseStore.upcomingSessions);
      setSelectedCourse(0);
    } else {
      setSelectedDomain(newSelectedDomain);

      const filteredCourses = courseStore.upcomingSessions.filter(
        (course: any) => course.domains.includes(newSelectedDomain.name)
      );

      setCourses(filteredCourses);
      setSelectedCourse(0);
    }
  };

  return (
    <div className={styles.container}>
      {page === "home" && (
        <div className="flex flex-col items-center gap-9">
          <div className="text-body-large font-body-large-bold sm:text-subheading text-primary-paradise-pink-850 text-center">
            Upcoming Classes to Help You Launch Your Career
          </div>
          <div className="flex flex-wrap justify-center gap-1.5">
            {domains.map((domain, index) => (
              <button
                key={index}
                className={`large-button border rounded-full px-8 py-3 transition duration-100 ease-in ${
                  selectedDomain.name === domain.name
                    ? styles.selected_domain
                    : styles.unselected_domain
                }`}
                onClick={() => filterByDomain(domain)}
              >
                {domain.name}
              </button>
            ))}
          </div>
        </div>
      )}

      {page === "search" && (
        <div className="text-body-large font-body-large-bold sm:text-subheading text-primary-paradise-pink-850 text-center lg:text-start">
          Upcoming Classes{" "}
          {searchStorage?.keyword && (
            <>
              {" "}
              for{" "}
              <span className="text-primary-paradise-pink-500">
                {searchStorage?.keyword}
              </span>
            </>
          )}
          {searchStorage?.location?.locationName?.label &&
            " in " + searchStorage.location.locationName?.label}
        </div>
      )}

      <div className="flex flex-col gap-2 sm:gap-4 rounded-2xl border border-primary-gray-200 p-1 sm:p-6 lg:px-10 lg:py-7 mt-7">
        {page === "home" && (
          <div className="text-body-medium sm:text-body-large font-body-medium-bold sm:font-body-large text-primary-pradise-pink-900 pt-2.5 pl-2.5 sm:p-0">
            Upcoming Classes
          </div>
        )}
        {courses && courses.length > 0 && (
          <div className="flex flex-col lg:flex-row gap-5">
            <div
              className={
                styles.custom_scrollbar +
                " flex flex-col gap-1.5 sm:gap-0 lg:gap-7 max-h-[560px] w-full lg:w-1/2 lg:pr-5 overflow-y-scroll"
              }
            >
              {courses.map((course, index) => (
                <div
                  key={index}
                  className={`flex items-center border rounded-xl p-1 cursor-pointer bg-primary-gray-50 border-primary-gray-50 ${
                    index === selectedCourse &&
                    "sm:bg-primary-paradise-pink-50 sm:border-primary-paradise-pink-500"
                  }`}
                  onClick={() => handleCourseSelection(index)}
                >
                  <img
                    src={
                      course?.SessionImage
                        ? `${env.IMAGE_API}${course?.SessionImage?.name}`
                        : "images/course-default.png"
                    }
                    alt={course?.title}
                    className="rounded-lg object-fill w-[169px] h-[111px] min-w-[169px] min-h-[111px]"
                  />
                  <div className="flex justify-between items-center w-full px-5">
                    <div>
                      <div className="mb-2.5">
                        <div className="text-body-small sm:text-body-medium font-body-small sm:font-body-medium text-primary-paradise-pink-850">
                          {course?.title}
                        </div>
                        <div className="text-caption-text sm:text-body-medium font-caption-text-bold sm:font-body-medium-bold text-primary-paradise-pink-800">
                          {formatDate(course?.start)} -{" "}
                          {formatDate(course?.end)}
                        </div>
                      </div>
                      <div className="text-caption-text sm:text-body-medium font-caption-text-bold sm:font-body-medium-bold flex items-start gap-1 text-primary-paradise-pink-750">
                        <img
                          src="/images/icons/location-purple.svg"
                          className="mt-1 h-[0.99rem] w-[0.99rem]"
                        />
                        {!course.isOnline &&
                        course?.sessionBooking &&
                        course?.sessionBooking.length > 0 &&
                        course?.sessionBooking[0].bookingRoom &&
                        course?.sessionBooking[0].bookingRoom.address &&
                        course?.sessionBooking[0].bookingRoom.address.name &&
                        course?.sessionBooking[0].bookingRoom.address.name !==
                          ""
                          ? course?.sessionBooking[0].bookingRoom.address.name
                          : ""}
                        {course.isOnline && "Online"}
                      </div>
                    </div>
                    <img
                      className="hidden sm:block"
                      src="/images/icons/arrow-right-pink.svg"
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="hidden lg:block w-px bg-black opacity-5" />
            <div className="hidden sm:block lg:hidden h-px bg-black opacity-5" />
            {courses[selectedCourse] && (
              <div className="hidden sm:block w-full lg:w-1/2">
                <img
                  className="rounded-xl h-[268px] w-full object-cover object-top"
                  src={
                    courses[selectedCourse]?.SessionImage
                      ? `${env.IMAGE_API}${courses[selectedCourse]?.SessionImage?.name}`
                      : "images/course-default.png"
                  }
                  alt=""
                />
                <div className="mt-3">
                  <div className="flex justify-between text-primary-paradise-pink-750">
                    <div className="body-small-bold flex gap-1 items-center">
                      <img src="/images/icons/location-purple.svg" />
                      {courses[selectedCourse]?.isOnline
                        ? "Online"
                        : courses[selectedCourse]?.sessionBooking[0]
                            ?.bookingRoom?.address?.name}
                    </div>
                    <div className="caption-text-bold flex gap-1 items-center">
                      {formatDate(courses[selectedCourse]?.start)} -{" "}
                      {formatDate(courses[selectedCourse]?.end)}
                      <img src="/images/icons/calendar.svg" />
                    </div>
                  </div>
                  <div
                    className="body-large mb-1 hover:cursor-pointer"
                    onClick={() => {
                      navigate(`/class/${courses[selectedCourse]?.id}`);
                    }}
                  >
                    {courses[selectedCourse]?.title}
                  </div>
                  <div className="body-medium mb-4">
                    {courses[selectedCourse]?.description}
                  </div>
                  <div className="flex gap-2 items-center mb-2">
                    <div className="body-medium-bold text-primary-paradise-pink-500">
                    {/* {userStore.currency} {courses[selectedCourse]?.price} */}
                    {formatCurrency(courses[selectedCourse]?.price, userStore.currency)}
                    </div>
                    <span>•</span>
                    <div className={styles.course_details_block}>
                      <span className="caption-text-bold text-primary-gray-500">
                        {courses[selectedCourse]?.rating}
                      </span>
                      <div className={styles.rating_text}>
                        {stars.map((starType, idx) => (
                          <img
                            key={idx}
                            width={16}
                            height={16}
                            src={`/images/icons/${
                              starType === "filled"
                                ? "star.svg"
                                : "empty-star.svg"
                            }`}
                            alt={starType}
                          />
                        ))}
                      </div>
                      <span className="caption-text text-primary-gray-300">
                        ({courses[selectedCourse]?.ratingsCount})
                      </span>
                    </div>
                  </div>
                  {courses[selectedCourse]?.domains &&
                    courses[selectedCourse]?.domains.length > 0 && (
                      <div className="caption-text-bold flex flex-wrap gap-2 items-center">
                        {courses[selectedCourse]?.domains.map(
                          (domain: string, index: number) => (
                            <div
                              key={index}
                              className="text-primary-paradise-pink-500 bg-primary-paradise-pink-100 px-4 py-1 rounded"
                            >
                              {domain}
                            </div>
                          )
                        )}
                      </div>
                    )}
                </div>
                <div className="flex gap-3 mt-[46px]">
                  <Button
                    type="primary"
                    text="Reserve a seat"
                    rightIcon="/images/icons/arrow-right-white.svg"
                    onClick={handlePayment}
                  />
                  <Button
                    type="secondary"
                    text="Learn More"
                    width="50%"
                    onClick={handleMore}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(UpcomingCohorts);
