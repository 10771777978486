import Container from "src/components/shared/Container";
import styles from "./stats.module.css";
import { useTranslation } from "react-i18next";

export default function CourseStats() {
  const { t } = useTranslation();

  return (
    <div className={styles.course_stats}>
      <Container>
        <div className={styles.course_stats_content}>
          <div className={styles.course_stats_item}>
            <img
              width={48}
              height={48}
              alt="Enrolled Icon"
              src="/images/icons/student.svg"
            />
            <div className={styles.course_stats_item_content}>
              <span className={styles.course_stats_item_value}>26,000</span>
              <span className={styles.course_stats_item_title}>
                {t("messages.studentsEnrolled")}
              </span>
            </div>
          </div>

          <div className={styles.course_stats_item}>
            <img
              width={48}
              height={48}
              alt="Enrolled Icon"
              src="/images/icons/award.svg"
            />
            <div className={styles.course_stats_item_content}>
              <span className={styles.course_stats_item_value}>14,000</span>
              <span className={styles.course_stats_item_title}>
                {t("messages.studentsCertified")}
              </span>
            </div>
          </div>

          <div className={styles.course_stats_item}>
            <img
              width={48}
              height={48}
              alt="Enrolled Icon"
              src="/images/icons/bag-1.svg"
            />
            <div className={styles.course_stats_item_content}>
              <span className={styles.course_stats_item_value}>130</span>
              <span className={styles.course_stats_item_title}>
                {t("messages.locations")}
              </span>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
