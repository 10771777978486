import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import styles from "./current.module.css";
import { Button } from "../../..";
import env from "../../../../config/env";
import { useStore } from "../../../../store";

const CurrentSessions: React.FC = () => {
    const navigate = useNavigate();
    const { courseStore } = useStore();
    const [selected, setSelected] = React.useState<any>(null);

    const sessions = courseStore.upcomingSessions;

    useEffect(() => {
        if (sessions.length > 0) {
            setSelected(sessions[0]);
        }
    }, [sessions]);

    const participate = () => {
        if (selected?.id) {
            courseStore.getCohortById(selected.id);
            return navigate(
                `/class/${selected.id}`
            );
        }
    }

    return (
        <div className={styles.playlist_container}>
            <div className={styles.playlist}>
                <div className={styles.playlist_list}>
                    {sessions.map((session: any, index) => (
                        <div
                            key={index}
                            className={`
                                ${styles.playlist_item}
                                ${selected?.id === session.id ? styles.playlist_item_selected : ""}
                            `}
                            onClick={() => setSelected(session)}
                        >
                            <img
                                width={156}
                                height={100}
                                alt={session.title}
                                src={`${env.IMAGE_API}${session.SessionImage?.name}`}
                                className={styles.playlist_item_image}
                            />

                            <div className={styles.playlist_item_info}>
                                <h4>{session.title}</h4>
                                <p>{session.description}</p>
                            </div>
                        </div>
                    ))}
                </div>

                <div className={styles.playlist_video}>
                    <img
                        width={156}
                        height={100}
                        alt={selected?.title}
                        className={styles.selected_picture}
                        src={`${env.IMAGE_API}${selected?.SessionImage?.name}`}
                    />

                    {/* <div className={styles.playlist_item_info}>
                        <h4>{selected?.title}</h4>
                        <p>{selected?.description}</p>
                    </div> */}

                    <Button
                        type="primary"
                        text="Participate"
                        customStyle={{ width: '100%' }}
                        onClick={() => participate()}
                    />
                </div>
            </div>
        </div>
    );
};

export default observer(CurrentSessions);