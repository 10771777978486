export function formatDateTimeRange(start: string, end: string): string {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const optionsDate: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'numeric', year: 'numeric' };
    const optionsTime: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };

    const formattedStartDate: string = startDate.toLocaleDateString(undefined, optionsDate);
    // const formattedStartTime: string = startDate.toLocaleTimeString(undefined, optionsTime);
    const formattedEndDate: string = endDate.toLocaleDateString(undefined, optionsDate);
    // const formattedEndTime: string = endDate.toLocaleTimeString(undefined, optionsTime);

    // if (
    //     startDate.getDate() === endDate.getDate() &&
    //     startDate.getMonth() === endDate.getMonth() &&
    //     startDate.getFullYear() === endDate.getFullYear()
    // ) {
    //     // Same date
    //     return `${formattedStartDate} - ${formattedStartTime} to ${formattedEndTime}`;
    // } else {
    //     // Different dates
    //     return `${formattedStartDate} to ${formattedEndDate}`;
    // }

    return `${formattedStartDate} to ${formattedEndDate}`;
}