import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "src/components/shared";
import { getAvailableClassrooms } from "src/store/Classroom";
import ClassroomsList from "./classroomsList";
import { duplicateSession, editSession } from "src/store/session";

interface IProps {
  data: any;
  isOnline: boolean | undefined;
  setClassroomSelection: (prop: boolean) => void;
}

export default function ClassroomSelection({
  data,
  isOnline,
  setClassroomSelection,
}: IProps) {
  const { type } = useParams();
  const navigate = useNavigate();

  const start = type === "new" ? data?.session.start : data.start;
  const end = type === "new" ? data?.session.end : data.end;
  const startTime = type === "new" ? data?.session.startTime : data.startTime;
  const endTime = type === "new" ? data?.session.endTime : data.endTime;

  const [loading, setLoading] = useState(false);
  const [classrooms, setClassrooms] = useState<any[] | null>(null);
  const [requestedClassrooms, setRequestedClassrooms] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const itemsPerPage = 6;
  const numberOfPages = count > 0 ? Math.ceil(count / itemsPerPage) : 1;

  const formatDate = (date: Date, options: Intl.DateTimeFormatOptions) => {
    return new Intl.DateTimeFormat("en-US", {
      ...options,
      timeZone: "UTC",
    }).format(date);
  };

  const formattedStartDate = formatDate(new Date(start), {
    month: "long",
    day: "2-digit",
  });

  const formattedEndDate = formatDate(new Date(end), {
    month: "long",
    day: "2-digit",
  });


  const formattedStartTime = formatDate(new Date(startTime), {
    hour: "2-digit",
    minute: "2-digit",
  });
  const formattedEndTime = formatDate(new Date(endTime), {
    hour: "2-digit",
    minute: "2-digit",
  });

  const formattedDateRange = `From ${formattedStartDate} To ${formattedEndDate}, ${formattedStartTime} to ${formattedEndTime}`;

  useEffect(() => {
    setLoading(true);
    getAvailableClassrooms(
      isOnline || false,
      itemsPerPage,
      itemsPerPage * (page - 1),
      start,
      end,
      startTime,
      endTime
    )
      .then((response) => {
        if (response !== null) {
          console.log("response", response);
          setClassrooms(Object.entries(response)[0][1] as any[]);
          setCount(Object.entries(response)[1][1] as number);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [page]);

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < numberOfPages) {
      setPage(page + 1);
    }
  };

  const links = [
    { onClick: () => navigate("/myclasses"), label: "My Classes" },
    { onClick: () => setClassroomSelection(false), label: "Add new class" },
  ];

  const handleSave = async () => {
    const newSessionData = {
      ...data,
      room: requestedClassrooms,
      actionBy: "teacher",
    };

    if (type === "new") {
      await duplicateSession(newSessionData).then((response) => {
        if (response !== null) {
          navigate("/teaching");
        }
      });
    } else {
      await editSession(newSessionData).then((response) => {
        if (response !== null) {
          navigate("/teaching");
        }
      });
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="new-session">
        <div className="links">
          {links.map((link, index) => (
            <div className="link" key={index}>
              <div onClick={link.onClick} className="label cursor-pointer">
                {link.label}
              </div>
              <div className="arrow">&gt;</div>
            </div>
          ))}
          <div className="active label">Classroom Selection</div>
        </div>

        <h1>Available classrooms</h1>
        <h3>{formattedDateRange}</h3>

        <ClassroomsList
          data={classrooms}
          page={page}
          numberOfPages={numberOfPages}
          count={count}
          requestedClassrooms={requestedClassrooms}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          setClassroomSelection={setClassroomSelection}
          handleSave={handleSave}
          setRequestedClassrooms={setRequestedClassrooms}
        />
      </div>
    </>
  );
}
