import React from "react";

import styles from "./topics.module.css";

interface IProps {
  title: string;
  description: string;
  icon: string;
}

const TopicItem: React.FC<IProps> = ({ title, description, icon }) => {
  return (
    <div className={styles.topic}>
      <div className="body-medium-bold flex items-center gap-2 text-primary-paradise-pink-500">
        <div className={styles.topic_icon}>
          <img src={icon} alt="Topic" />
        </div>
        <span>{title}</span>
      </div>

      <p className="body-small text-primary-purple-800">{description}</p>

      {/* <div className={styles.topic_footer}>
                <span>Learn more</span>
                <img
                    src="/images/icons/arrow-right-purple.svg"
                    alt="Facebook"
                    width={15}
                    height={15}
                />
            </div> */}
    </div>
  );
};

export default TopicItem;
