import { useNavigate } from "react-router-dom";
import { Rate } from "antd";
import env from "../../../../config/env";

interface IProps {
  type: "course" | "teacher" | "classroom";
  session: any;
  setFeedback: (feedback: string) => void;
  setRating: (rating: number) => void;
}

export default function RateItem({
  session,
  setRating,
  setFeedback,
  type,
}: IProps) {
  const navigate = useNavigate();

  let image = session?.SessionImage;
  let title = session?.title;
  let description = session?.description;

  if (type === "teacher") {
    image = session?.teacher?.image;
    title = session?.teacher?.fullName;
    description = session?.teacher?.description;
  } else if (type === "classroom") {
    image = session?.sessionBooking[0]?.bookingRoom?.logo;
    title = session?.sessionBooking[0]?.bookingRoom?.name;
    description = session?.sessionBooking[0]?.bookingRoom?.description;
  }

  const handleTitleClick = () => {
    navigate(`/class/${session.id}`);
  };

  return (
    <div className="rate-item">
      <div className="image-container">
        <img
          src={
            image && Object.keys(image).length > 0
              ? `${env.IMAGE_API}${image?.name}`
              : ""
          }
          alt={type + "-image"}
        />
        <h2 onClick={handleTitleClick}>{title}</h2>
        {type === "teacher" && <p>{session?.teacher?.profession}</p>}
        {type !== "course" && <p>{description}</p>}
        {/* {type === "teacher" && (
          <div className="links">
            <a
              className="link"
              href={session?.teacher?.linkedinUrl}
              target="_blank"
            >
              <img src="" alt="" />
            </a>
            <a className="link" href="">
              <img src="" alt="" />
            </a>
          </div>
        )} */}
        {type === "course" && (
          <div className="domains">
            {session?.domains.map((domain: any, index: number) => (
              <div className="domain" key={index}>
                {domain}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="rating-container">
        <div className="rating">
          <h3>Your rating</h3>
          <Rate
            defaultValue={3.5}
            onChange={(value) => setRating(value)}
            allowHalf
            style={{ fontSize: "30px" }}
          />
        </div>

        <div className="review">
          <h3>Your review</h3>
          <textarea
            placeholder={"Write review about this " + type}
            onChange={(e) => setFeedback(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}
