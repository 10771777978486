import React from "react";
import Modal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import { observer } from "mobx-react";
import styles from "../ConfirmationPopup/confirmationPopup.module.css";
import { Button, Input } from "../../../components";
import { useStore } from "../../../store";

interface IProps {
    title: string;
    text?: string;
    icon?: string;
    isOpen: boolean;
    requirePassword?: boolean;
    close: () => void;
    confirm: () => void;
}

const ConfirmationPopup: React.FC<IProps> = ({ title, text, icon, isOpen, requirePassword, close, confirm }) => {
    const { userStore } = useStore();
    
    const [password, setPassword] = React.useState("");
    const [validPassword, setValidPassword] = React.useState(true);

    const handlePasswordInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setValidPassword(true);
    };

    const closePopup = () => {
        setPassword("");
        setValidPassword(true)
        close();
    }

    const confirmPopup = async () => {
        if (requirePassword) {
            await userStore
                .confirmPassword(password)
                .then(async (res) => {
                    if (res?.isMatch == true) {
                        setPassword("");
                        confirm();
                        close();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setValidPassword(false)
                });
        } else {
            confirm();
            close();
        }

    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={close}
            style={customStyles}
        >
            <div className={styles.content}>
                <div className={styles.icon}>
                    <img src={icon || "images/icons/i.png"} alt="i" />
                </div>

                <div>
                    <div className={styles.title}>{ title }</div>
                    <div className={styles.text}>{ text }</div>
                    {requirePassword && (
                        <div className="field">
                            <label htmlFor="password">Password</label>
                            <div className="input-field">
                                <div className="input">
                                    <Input
                                        type="password"
                                        label="Password"
                                        value={password}
                                        onChange={handlePasswordInputChange}
                                    />
                                    {!validPassword &&
                                        <span className={styles.error}>
                                            Invalid Password
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.button_container}>
                <Button
                    type="secondary"
                    text="Back"
                    onClick={closePopup}
                    customStyle={{ flex: 1 }}
                />
                <Button
                    type="primary"
                    text="Confirm"
                    onClick={confirmPopup}
                    customStyle={{ flex: 1 }}
                />
            </div>
            
        </Modal>
    );
};

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        border: "1px solid #ccc",
        transform: "translate(-50%, -50%)",
    },
};

export default observer(ConfirmationPopup);