import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStore } from "../../../store";
import {
  Navbar,
  Loading,
  UploadImage,
  Input,
  TextEditor,
  Select as RSelect,
  MultiSelectInput,
  Button,
} from "src/components/shared";
import Select from "react-select";
import "./styles.css";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import "draft-js/dist/Draft.css";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function NewCourse() {
  const navigate = useNavigate();
  const { userStore, courseStore } = useStore();

  useEffect(() => {
    userStore.getUser().then(() => {
      const user = userStore.user;
      if (!user) {
        navigate("/login");
      }
    });
  }, []);

  const user = userStore.user;

  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState<File | null>(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [syllabus, setSyllabus] = useState("");
  const [syllabusEditorState, setSyllabusEditorState] = useState(
    EditorState.createEmpty()
  );
  const [forWho, setForWho] = useState("");
  const [objectifs, setObjectifs] = useState("");
  const [objectifsEditorState, setObjectifsEditorState] = useState(
    EditorState.createEmpty()
  );
  const [objectivesImage, setObjectivesImage] = useState<File | null>(null);
  const [perspectives, setPerspectives] = useState("");
  const [perspectivesEditorState, setPerspectivesEditorState] = useState(
    EditorState.createEmpty()
  );
  const [perspectivesImage, setPerspectivesImage] = useState<File | null>(null);
  const [price, setPrice] = useState("");
  const [level, setLevel] = useState("Beginner");
  const [createdBy, setCreatedBy] = useState("");
  const [skills, setSkills] = useState([]);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [domains, setDomains] = useState([]);
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState<
    { value: string; label: string }[]
  >([]);

  const [currency, setCurrency] = useState("USD");
  const [language, setLanguage] = useState("English");
  const [courseAttachment, setCourseAttachment] = useState<any | null>(null);

  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const summaryRef = useRef<HTMLTextAreaElement>(null);

  const [courseTestimonials, setCourseTestimonials] = useState([
    {
      author: "",
      content: "",
      photo: {
        preview: "images/default-user.jfif",
        data: {},
        container: {},
      },
    },
  ]);

  const courseLevels = [
    { value: 1, label: "Beginner" },
    { value: 2, label: "Intermediate" },
    { value: 3, label: "Advanced" },
  ];

  const currencyOptions = [
    { value: "USD", label: "USD" },
    { value: "EUR", label: "EUR" },
    { value: "CAD", label: "CAD" },
    { value: "TND", label: "TND" },
  ];

  const languageOptions = [
    { value: "English", label: "English" },
    { value: "French", label: "French" },
  ];

  useEffect(() => {
    setLoading(true);
    Promise.all([courseStore.getAllDomains(), courseStore.getAllCategories()])
      .then(([domainsResponse, categoriesResponse]) => {
        if (domainsResponse !== null) {
          const domains = domainsResponse.map((domain: any) => ({
            value: domain.id,
            label: domain.name,
          }));
          setDomains(domains);
        }

        if (categoriesResponse !== null) {
          console.log(categoriesResponse);
          const categories = categoriesResponse.map((category: any) => ({
            value: category.id,
            label: category.name,
          }));
          setCategories(categories);
          setCategory(categories[0].value);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const links = [{ path: "/mycourses", label: "My Courses" }];

  const handleSave = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const descriptionLength = description.trim().length;
    const summaryLength = forWho.trim().length;

    if (descriptionLength < 300) {
      descriptionRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setLoading(false);
      return;
    }

    if (summaryLength < 400) {
      summaryRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setLoading(false);
      return;
    }

    const course = {
      title: title,
      description: description,
      image: image,
      difficulty: level,
      courseCreator: createdBy,
      forWho: forWho,
      price: price,
      syllabus:
        syllabus.length > 0
          ? JSON.parse(syllabus)
          : { blocks: [], entityMap: {} },
      prerequisite: skills,
      objectifs: {
        content:
          objectifs.length > 0
            ? JSON.parse(objectifs)
            : { blocks: [], entityMap: {} },
        image: objectivesImage,
      },
      perspectives: {
        content:
          perspectives.length > 0
            ? JSON.parse(perspectives)
            : { blocks: [], entityMap: {} },
        image: perspectivesImage,
      },
      domains: selectedDomains.map((domain: any) => {
        return { id: domain.value, name: domain.label };
      }),
      categoryId: categories.find((cat: any) => cat.label === category)?.value,
      currency: currency,
      language: language,
      testimonials: courseTestimonials,
      file:
        courseAttachment !== null
          ? courseAttachment
          : { name: "", originalFileName: "" },
    };

    if (image) {
      await userStore.uploadPicture(image).then((response) => {
        course.image = response;
      });
    }

    if (perspectivesImage) {
      await userStore.uploadPicture(perspectivesImage).then((response) => {
        course.perspectives.image = response;
      });
    }

    if (objectivesImage) {
      await userStore.uploadPicture(objectivesImage).then((response) => {
        course.objectifs.image = response;
      });
    }

    await courseStore
      .newCourse(course)
      .then((response) => {
        console.log(course);
        console.log(response);
        setLoading(false);
        navigate("/mycourses");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleSyllabusEditorChange = (newEditorState: EditorState) => {
    setSyllabusEditorState(newEditorState);
    const rawContentState = convertToRaw(newEditorState.getCurrentContent());
    setSyllabus(JSON.stringify(rawContentState));
  };

  const handleObjectifsEditorChange = (newEditorState: EditorState) => {
    setObjectifsEditorState(newEditorState);
    const rawContentState = convertToRaw(newEditorState.getCurrentContent());
    setObjectifs(JSON.stringify(rawContentState));
  };

  const handlePerspectivesEditorChange = (newEditorState: EditorState) => {
    setPerspectivesEditorState(newEditorState);
    const rawContentState = convertToRaw(newEditorState.getCurrentContent());
    setPerspectives(JSON.stringify(rawContentState));
  };

  const updateTestimonialField = (index: any, field: any, value: any) => {
    const updatedTestimonials = [...courseTestimonials];
    updatedTestimonials[index] = {
      ...updatedTestimonials[index],
      [field]: value,
    };
    setCourseTestimonials(updatedTestimonials);
  };

  const photoChangeHandlerTestimonial = async (index: any, e: any) => {
    const updatedTestimonials = [...courseTestimonials];
    const file = e.target.files[0];
    updatedTestimonials[index].photo = {
      preview: URL.createObjectURL(file),
      data: file,
      container: {},
    };
    setCourseTestimonials(updatedTestimonials);
    const formData = new FormData();
    formData.append("file", file);
    try {
      userStore.uploadPicture(file).then((response) => {
        updatedTestimonials[index].photo.container = response;
        setCourseTestimonials(updatedTestimonials);
      });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  // Add new testimonial
  const addNewTestimonial = () => {
    setCourseTestimonials([
      ...courseTestimonials,
      {
        author: "",
        content: "",
        photo: {
          preview: "images/default-user.jfif",
          data: {},
          container: {},
        },
      },
    ]);
  };

  // Remove testimonial
  const removeTestimonial = (index: any) => {
    if (courseTestimonials.length > 1) {
      const updatedTestimonials = [...courseTestimonials];
      updatedTestimonials.splice(index, 1);
      setCourseTestimonials(updatedTestimonials);
    }
  };

  const updateCourseAttachment = async (e: any) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      userStore.uploadFile(file).then((response) => {
        setCourseAttachment(response);
      });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Navbar />

      <form className="new-course" onSubmit={handleSave}>
        <div className="links">
          {links.map((link, index) => (
            <div className="link" key={index}>
              <Link to={link.path} className="label">
                {link.label}
              </Link>
              <div className="arrow">&gt;</div>
            </div>
          ))}
          <div className="active label">Add new course</div>
        </div>

        <h1>Add new course</h1>

        <div className="inputs">
          <div className="left-column">
            <div className="field">
              <label htmlFor="picture">Course banner</label>
              <div className="input-field">
                <UploadImage image={image} setImage={setImage} width="80%" />
              </div>
            </div>
          </div>

          <div className="right-column">
            <div className="field">
              <label htmlFor="title">Title</label>
              <div className="input-field">
                <div className="input">
                  <Input
                    type="text"
                    label="Title"
                    required
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label htmlFor="description">
                <div className="w-full flex justify-between items-center">
                  <span>Description </span>
                  {description.length < 300 && (
                    <small className="text-red-500">
                      {`Write at least ${
                        300 - description.length
                      } more characters.`}
                    </small>
                  )}
                </div>
              </label>
              <div className="input-field">
                <textarea
                  className="bio resize-none"
                  required
                  id="description"
                  placeholder="Description"
                  value={description}
                  ref={descriptionRef}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field">
          <label htmlFor="description">
            <div className=" w-full flex justify-between items-center">
              <span>Course Summary </span>
              {forWho.length < 400 && (
                <small className="text-red-500 font-inter font-normal">
                  {`Write at least ${400 - forWho.length} more characters.`}
                </small>
              )}
            </div>
          </label>
          <div className="input-field">
            <textarea
              className="bio resize-none"
              required
              id="summary"
              placeholder="Course Summary"
              value={forWho}
              ref={summaryRef}
              onChange={(e) => setForWho(e.target.value)}
            />
          </div>
        </div>

        <div className="field">
          <label htmlFor="syllabus">Syllabus</label>
          <div className="input-field">
            <div className="input_container">
              <div className="border border-gray-300 rounded-md p-4 min-h-[250px]">
                <Editor
                  editorState={syllabusEditorState}
                  onEditorStateChange={handleSyllabusEditorChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col justify-start items-start">
          <div className="w-full">
            <label htmlFor="objectifs">Objectifs</label>
          </div>
          <div className="w-full flex flex-col md:flex-row justify-start items-center space-y-12 md:space-y-0 md:space-x-8">
            <div className="w-full">
              <div className="border border-gray-300 rounded-md p-4 min-h-[250px]">
                <Editor
                  editorState={objectifsEditorState}
                  onEditorStateChange={handleObjectifsEditorChange}
                />
              </div>
            </div>
            <div className="w-full md:w-[450px] input-field">
              <UploadImage
                image={objectivesImage}
                setImage={setObjectivesImage}
              />
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col justify-start items-start">
          <div className="w-full">
            <label htmlFor="perspectives">Perspectives</label>
          </div>
          <div className="w-full flex flex-col md:flex-row justify-start items-center space-y-12 md:space-y-0 md:space-x-8">
            <div className="w-full">
              <div className="border border-gray-300 rounded-md p-4 min-h-[250px]">
                <Editor
                  editorState={perspectivesEditorState}
                  onEditorStateChange={handlePerspectivesEditorChange}
                />
              </div>
            </div>
            <div className="w-full md:w-[450px] input-field">
              <UploadImage
                image={perspectivesImage}
                setImage={setPerspectivesImage}
              />
            </div>
          </div>
        </div>

        <div className="grid gap-4 w-full">
          {courseTestimonials.map((testimonial, index) => (
            <div key={index} className="border p-4 rounded-lg">
              <div className="w-full flex flex-col md:flex-row justify-start items-center gap-8">
                <div className="w-full flex flex-col md:flex-row justify-start items-start gap-4">
                  {/* Testimonial Content */}
                  <div className="w-full md:w-1/2 flex flex-col space-y-2">
                    <label className="font-medium text-gray-700">
                      Testimonial {index + 1}
                    </label>
                    <textarea
                      className="border rounded-lg p-2 text-gray-900 focus:outline-none h-[150px] resize-none"
                      value={testimonial.content}
                      onChange={(e) =>
                        updateTestimonialField(index, "content", e.target.value)
                      }
                      placeholder="Testimonial Content"
                      required
                    />
                  </div>

                  {/* Author Input */}
                  <div className="w-full md:w-1/2 flex flex-col space-y-2">
                    <label className="font-medium text-gray-700">
                      Author {index + 1}
                    </label>
                    <textarea
                      className="border rounded-lg p-2 text-gray-900 focus:outline-none h-[150px] resize-none"
                      value={testimonial.author}
                      onChange={(e) =>
                        updateTestimonialField(index, "author", e.target.value)
                      }
                      placeholder="Testimonial Author"
                      required
                    />
                  </div>
                </div>

                {/* Image Upload */}
                <div className="flex flex-col justify-start md:justify-center items-start md:items-center space-y-4 w-[400px]">
                  <img
                    alt="Author"
                    src={
                      testimonial.photo.preview ||
                      "assets/images/default-user.jfif"
                    }
                    className="w-[200px] h-[200px] object-cover border"
                  />
                  <input
                    type="file"
                    className="w-[230px]"
                    onChange={(e) => photoChangeHandlerTestimonial(index, e)}
                  />
                </div>
              </div>

              {/* Remove Button */}
              {index !== 0 && (
                <div className="mt-4">
                  <Button
                    text="Remove Testimonial"
                    type="primary"
                    customColor="#373737"
                    width="250px"
                    preventDefault={true}
                    onClick={() => removeTestimonial(index)}
                  />
                </div>
              )}
            </div>
          ))}
          {/* Add Testimonial Button */}
          <div>
            <Button
              text="Add Testimonial"
              type="primary"
              width="200px"
              preventDefault={true}
              onClick={() => addNewTestimonial()}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4 my-6">
          {/* Row 1 */}
          <div className="col-span-4 md:col-span-1">
            <label className="block">Price per person</label>
            <Input
              type="number"
              fullWidth
              value={price}
              required
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
          <div className="col-span-4 md:col-span-1">
            <label className="block">Currency</label>
            <RSelect
              options={currencyOptions}
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              label="Select Currency"
            />
          </div>
          <div className="col-span-4 md:col-span-1">
            <label className="block">Language</label>
            <RSelect
              options={languageOptions}
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              label="Select Language"
            />
          </div>
          <div className="col-span-4 md:col-span-1">
            <label className="block">Created by (optional)</label>
            <Input
              type="text"
              fullWidth
              value={createdBy}
              onChange={(e) => setCreatedBy(e.target.value)}
            />
          </div>

          {/* Row 2 */}
          <div className="col-span-4 md:col-span-2">
            <label className="block">Course level</label>
            <RSelect
              options={courseLevels}
              value={level}
              onChange={(e) => setLevel(e.target.value)}
              label="Select Course Level"
            />
          </div>
          <div className="col-span-4 md:col-span-2">
            <label className="block">Attachment (optional)</label>
            <input
              className="fileInput"
              type="file"
              id="courseAttachment"
              onChange={(e) => {
                updateCourseAttachment(e);
              }}
            />
          </div>

          {/* Row 3 */}
          <div className="col-span-4 md:col-span-2">
            <MultiSelectInput
              text="Required skills"
              name="skills"
              onValueChange={setSkills}
              required
            />
          </div>
          <div className="col-span-4 md:col-span-2">
            <label className="block">Domain</label>
            <Select
              isMulti
              options={domains}
              value={selectedDomains}
              closeMenuOnSelect={false}
              onChange={(e: any) => setSelectedDomains(e)}
              placeholder="Select domain(s)"
              required
            />
          </div>

          {/* Row 4 */}
          <div className="col-span-4 md:col-span-2">
            <label className="block">Category</label>
            <RSelect
              label="Select category"
              fullWidth
              options={categories}
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
          </div>
        </div>

        <div className="buttons">
          <Button
            text="Cancel"
            type="primary"
            customColor="#373737"
            width="400px"
            preventDefault={true}
            onClick={() => navigate(-1)}
          />
          <Button text="Save" type="primary" width="400px" submit />
        </div>
      </form>
    </>
  );
}
