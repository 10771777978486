import React from "react";
import { useNavigate } from "react-router-dom";

import styles from "./becomeMember.module.css";
import { Button } from "src/components/shared";

const BecomeMember: React.FC = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const navigation = () => {
    if (token) {
      navigate("/search");
      window.scrollTo(0, 0);
    } else {
      navigate("/register");
    }
  };

  return (
    <div className={styles.container}>
      <h3 className="text-body-large font-body-large-bold sm:text-heading-large ">
        Get{" "}
        <span className="text-primary-paradise-pink-500">One Step Closer</span>{" "}
        to <br /> Achieving your Dreams
      </h3>

      <p className="text-body-medium font-body-small sm:text-body-medium sm:font-body-medium mb-2.5">
        Whether you’re looking to gain new skills or elevate your career, take
        the leap <br className="hidden sm:block" /> toward your goals backed by
        a supportive learning community.
      </p>

      <Button
        type="primary"
        text="Join Now"
        width="fit-content"
        onClick={navigation}
      />
    </div>
  );
};

export default BecomeMember;
