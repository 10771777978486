import React from "react";

import styles from "./steps.module.css";

interface IProps {
  icon: string;
  title: string;
  subTitle: string;
  index: number;
  colored: boolean;
}

const StepItem: React.FC<IProps> = ({
  icon,
  title,
  subTitle,
  index,
  colored,
}) => {
  return (
    <div className="w-full sm:w-1/3">
      <div className="relative mb-9">
        <div
          className={`w-full h-[3px] ${
            colored ? "bg-primary-purple-600" : "bg-primary-paradise-pink-850"
          }`}
        />
        <div
          className={`absolute top-[-17.5px] body-medium-bold flex justify-center items-center text-primary-paradise-pink-50 w-[38px] h-[38px] rounded-full ${
            colored ? "bg-primary-purple-600" : "bg-primary-paradise-pink-850"
          }`}
        >
          {index}
        </div>
      </div>
      <div className="flex flex-col justify-between gap-5 sm:gap-11 h-full mr-4">
        <div className="text-start">
          <h3
            className={`text-body-large lg:text-subheading font-body-large-bold lg:font-subheading ${
              colored
                ? "text-primary-purple-500"
                : "text-primary-paradise-pink-800"
            } mb-4`}
          >
            {title}
          </h3>
          <span className="body-medium text-primary-paradise-pink-900">
            {subTitle}
          </span>
        </div>
        <div
          className={`flex justify-center items-center rounded-xl ${
            colored ? "bg-primary-purple-50" : "bg-primary-paradise-pink-50"
          }`}
        >
          <img
            className="fit-cover w-full h-[200px]"
            alt="partner"
            loading="lazy"
            src={icon}
          />
        </div>
      </div>
    </div>
  );
};

export default StepItem;
