import React from "react";

type ListItemProps = {
  name: string;
  icon: string;
  oneItem?: boolean;
  selectedItems: string[];
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
  setFocus?: React.Dispatch<React.SetStateAction<boolean>>;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
  setSeletecDomains?: React.Dispatch<React.SetStateAction<string[]>>;
  isDisabled?: boolean;
};

const ListItem: React.FC<ListItemProps> = ({
  name,
  icon,
  oneItem,
  selectedItems,
  setSelectedItems,
  setFocus,
  setSearch,
  setSeletecDomains,
  isDisabled,
}) => {
  const isSelected = selectedItems.includes(name);

  const handleSelect = () => {
    if (isDisabled) return;
    setFocus && setFocus(false);
    setSearch && setSearch("");
    if (isSelected) {
      setSelectedItems(selectedItems.filter((item: any) => item !== name));
      if (setSeletecDomains) {
        setSeletecDomains(selectedItems.filter((item: any) => item !== name));
      }
    } else {
      if (oneItem) {
        setSelectedItems([name]);
        if (setSeletecDomains) {
          setSeletecDomains([name]);
        }
        return;
      }
      setSelectedItems([...selectedItems, name]);
      if (setSeletecDomains) {
        setSeletecDomains([...selectedItems, name]);
      }
    }
  };

  return (
    <li
      onClick={isDisabled ? undefined : handleSelect}
      className={`p-2 border rounded-2xl ${
        isDisabled
          ? "border-primary-gray-50 cursor-not-allowed"
          : "cursor-pointer"
      } ${
        isSelected && !isDisabled
          ? "bg-primary-paradise-pink-50 border-primary-paradise-pink-500"
          : "border-primary-gray-50"
      }`}
    >
      <div className="flex items-center space-x-3">
        <div
          className={`p-4 rounded-lg flex items-center justify-center ${
            isDisabled ? "border-primary-gray-50" : "bg-primary-paradise-pink-50"
          }`}
        >
          <img src={icon} alt="" />
        </div>
        <p className={`body-small ${isDisabled ? "cursor-not-allowed": "cursor-pointer"}`}>{name}</p>
      </div>
    </li>
  );
};

export default ListItem;
