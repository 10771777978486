import React from "react";

import styles from "./searched.module.css";
import { Courses } from "../../../../components";
import { observer } from "mobx-react";
import { useStore } from "../../../../store";

const SearchedCourses: React.FC = () => {
    const { courseStore } = useStore();
    
    const courses: any = courseStore.searchedCourses;
    
    
    return (
        <main className={styles.courses_list}>
            {courses.length > 0 ? (
                courses?.map((course: any, index: string) => (
                    <Courses.CourseItem
                        key={index}
                        type="class"
                        course={{
                            id: course?.id,
                            title: course?.title,
                            category: (course?.domains && course?.domains.length > 0) ? course?.domains[0]?.name : "",
                            description: course?.description,
                            image: course?.SessionImage?.name,
                            price: course?.price,
                            rating: course?.rating,
                            ratingCount: course?.ratingsCount,
                            provider: {
                                name: course?.tutors?.fullName,
                                logo: "/images/we-code.jpeg",
                            },
                            startTime: course?.startTime,
                            endTime: course?.endTime,
                            location: course?.isOnline ? "Online" : "Synergy Space",
                    }}
                    />
                ))
            ) : (
                <div className={styles.no_courses}>
                    <h2>No Courses Found</h2>
                </div>
            )}
        </main>
    );
};

export default observer(SearchedCourses);
