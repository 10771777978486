import React from "react";

import "../index.css";

interface TimeRangeProps {
  startTime: any;
  setStartTime: (time: any) => void;
  endTime: any;
  setEndTime: (time: any) => void;
}

const TimeRange: React.FC<TimeRangeProps> = ({
  startTime,
  endTime,
  setStartTime,
  setEndTime,
}: TimeRangeProps) => {
  const CustomInput = React.forwardRef<any, any>(
    ({ value, onClick, placeholder }, ref) => (
      <input
        className="custom-input"
        onClick={onClick}
        ref={ref}
        placeholder={placeholder}
        value={value}
        onChange={() => {}}
      />
    )
  );

  return (
    <div className="date-range-picker">
      <input
        className="custom-input"
        type="number"
        required
        max={23}
        min={0}
        value={startTime}
        onChange={(e) => setStartTime(e.target.value)}
        placeholder="Start hour"
      />
      <span className="arrow">→</span>
      <input
        className="custom-input"
        type="number"
        required
        max={23}
        min={0}
        value={endTime}
        onChange={(e) => setEndTime(e.target.value)}
        placeholder="End hour"
      />
      <img src="/images/clock.png" alt="" />
    </div>
  );
};

export default TimeRange;
