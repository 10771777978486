interface partner {
  id: string;
  link: string;
  picture: string;
}

const partners: partner[] = [
  {
    id: "1",
    picture: "/images/partners/esprit.png",
    link: "https://www.esprit.tn",
  },
  {
    id: "2",
    picture: "/images/partners/unity.png",
    link: "https://unity.com",
  },
  {
    id: "4",
    picture: "/images/partners/google.png",
    link: "https://www.google.com",
  },
  {
    id: "5",
    picture: "/images/partners/certnexus.png",
    link: "https://certnexus.com",
  },
  {
    id: "6",
    picture: "/images/partners/comptia.png",
    link: "https://www.comptia.org",
  },
  {
    id: "7",
    picture: "/images/partners/ooredoo.png",
    link: "https://www.ooredoo.com",
  },
  {
    id: "8",
    picture: "/images/partners/sofrecom.png",
    link: "https://www.sofrecom.com",
  },
  {
    id: "9",
    picture: "/images/partners/ebrd.png",
    link: "https://www.ebrd.com/starventure/start-ups",
  },
  {
    id: "10",
    picture: "/images/partners/mobilefordevelopment.png",
    link: "https://www.gsma.com/mobilefordevelopment",
  },
  {
    id: "11",
    picture: "/images/partners/ifc.png",
    link: "https://www.ifc.org/en/where-we-work/middle-east/promoting-inclusion/she-wins-arabia",
  },
  {
    id: "12",
    picture: "/images/partners/taqadam.png",
    link: "https://taqadam.kaust.edu.sa",
  },
  {
    id: "3",
    picture: "/images/partners/aws.png",
    link: "https://aws.amazon.com",
  },
];

export default partners;
