import React, { CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import styles from "./button.module.css";

interface ButtonProps {
  text?: string;
  icon?: string;
  rightIcon?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  type?: "primary" | "secondary" | "tertiary" | "primary_dark" | "outline";
  customStyle?: CSSProperties;
  customColor?: string;
  width?: string;
  preventDefault?: boolean;
  isWishListClicked?: boolean;
  submit?: boolean;
  isLoading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  text,
  icon,
  rightIcon,
  onClick,
  isDisabled,
  type,
  customStyle,
  customColor,
  width,
  preventDefault,
  isWishListClicked,
  submit,
  isLoading,
}) => {
  let buttonType;
  let customColorStyle;
  let customWidth = { width: `${width || "100%"}` };

  if (isDisabled) {
    buttonType = styles.disabled_button;
  } else {
    switch (type) {
      case "primary":
        buttonType = styles.primary_button;
        break;
      case "secondary":
        buttonType = styles.secondary_button;
        break;
      case "tertiary":
        buttonType = styles.tertiary_button;
        break;
      case "primary_dark":
        buttonType = styles.primary_dark_button;
        break;
      case "outline":
        buttonType = styles.outline_button;
        break;
      default:
        buttonType = styles.primary_button;
        break;
    }
  }

  if (customColor) {
    switch (type) {
      case "primary":
        customColorStyle = {
          backgroundColor: customColor,
          borderColor: customColor,
          color: "#FFFFFF",
          border: "1px solid",
        };
        break;
      case "secondary":
        customColorStyle = {
          backgroundColor: "#FFFFFF",
          borderColor: customColor,
          color: customColor,
          border: "1px solid",
        };
        break;
      default:
        customColorStyle = {
          backgroundColor: customColor,
          borderColor: customColor,
          border: "1px solid",
        };
        break;
    }
  }

  if (isWishListClicked) {
    buttonType = styles.wishlist_disabled;
  }

  if (isLoading) {
    return (
      <button
        disabled
        type="button"
        className={`
                ${styles.disabled_button}
                ${styles.button}
            `}
        style={{ ...customStyle, ...customColorStyle, ...customWidth }}
      >
        <ClipLoader color={"#CA2D5D"} size={20} />
      </button>
    );
  }

  return (
    <button
      type={submit ? "submit" : "button"}
      disabled={isWishListClicked}
      onClick={(event?: any) => {
        if (preventDefault) {
          event?.preventDefault();
        }
        if (onClick && !isDisabled) {
          onClick();
        }
      }}
      className={`
                ${customColor ? "" : buttonType}
                ${styles.button}
            `}
      style={{ ...customStyle, ...customColorStyle, ...customWidth }}
    >
      {icon && <img src={icon} alt="icon" width={20} height={20} />}
      {text}
      {rightIcon && <img src={rightIcon} alt="icon" width={20} height={20} />}
    </button>
  );
};

export default Button;
