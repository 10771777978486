import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import styles from "./navbar.module.css";
import { Button } from "..";
import { useStore } from "../../../store";
import { observer } from "mobx-react";
import Beta from "./Beta";
import LanguageDropdown from "./LanguageDropdown";
import env from "../../../config/env";

const Navbar: React.FC<{ defaultTransparent?: boolean }> = ({
  defaultTransparent,
}) => {
  const navigate = useNavigate();
  const { userStore } = useStore();

  let user = userStore.user;
  let role = userStore.user?.roles[user?.roles.length - 1].name || "Student";
  let userRoles = user?.roles.map((role: any) => {
    return role.name;
  });
  const [dropdownItems, setDropdownItems] = useState<any[]>([
    {
      name: "My Courses",
      path: "/myclasses",
    },
    {
      name: "My Classes",
      path: "/myclasses",
    },
    {
      name: "Wishlist",
      path: "/favorites",
    },
    {
      name: "My Reviews",
      path: "/",
    },
    {
      name: "Settings",
      path: "/myprofile",
    },
  ]);

  const [language, setLanguage] = useState("English");
  const [currency, setCurrency] = useState("USD");
  const [showBeta, setShowBeta] = useState(true);
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [transparent, setTransparent] = useState(defaultTransparent);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const userPicRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!user) {
      userStore.getUser().then(() => {
        user = userStore.user;
        role = user?.roles[user?.roles.length - 1].name;
        userRoles = user?.roles.map((role: any) => {
          return role.name;
        });
        initDropDownItems();
      });
    } else {
      initDropDownItems();
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        userPicRef.current &&
        !userPicRef.current.contains(event.target as Node)
      ) {
        setDropdownMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setDropdownMenu]);

  const handleLogout = () => {
    userStore.logout();
    navigate("/");
  };

  const handleMobileLogout = () => {
    setShowSidebar(false);
    userStore.logout();
    navigate("/");
  };

  const initDropDownItems = () => {
    let items = [];

    if (userRoles && userRoles.length > 0) {
      if (role.toLowerCase() === "student") {
        items.push(
          {
            name: "Profile",
            path: "/myprofile",
          },
          {
            name: "Browse Courses",
            path: "/search",
          },
          {
            name: "My Classes",
            path: "/myclasses",
          },
          {
            name: "My Reviews",
            path: "/",
          },
          {
            name: "Wishlist",
            path: "/favorites",
          },
          {
            name: "Settings",
            path: "/myprofile",
            state: { activeTab: "privacy" },

          }
        );
      }

      if (role.toLowerCase() === "teacher") {
        items.push(
          {
            name: "Profile",
            path: "/myprofile",
          },
          {
            name: "Browse Courses",
            path: "/search",
          },
          {
            name: "Manage Courses",
            path: "/teaching",
            state: { activeTab: 1 },
          },
          {
            name: "Manage Classes",
            path: "/teaching",
          },
          {
            name: "My Classes",
            path: "/myclasses",
          },
          {
            name: "My Reviews",
            path: "/",
          },
          {
            name: "Wishlist",
            path: "/favorites",
          },
          {
            name: "Settings",
            path: "/myprofile",
            state: { activeTab: "privacy" },

          }
        );
      }
      if (role.toLowerCase() === "host") {
        items.push(
          {
            name: "Profile",
            path: "/myprofile",
          },
          {
            name: "Browse Courses",
            path: "/search",
          },
          {
            name: "Manage Spaces",
            path: "/classroomsManagement",
          },
          {
            name: "My Classes",
            path: "/myclasses",
          },
          {
            name: "My Reviews",
            path: "/",
          },
          {
            name: "Wishlist",
            path: "/favorites",
          },
          {
            name: "Settings",
            path: "/myprofile",
            state: { activeTab: "privacy" },
          }
        );
      }
      if (role.toLowerCase() === "admin") {
        items.push(
          {
            name: "Profile",
            path: "/myprofile",
          },
          {
            name: "Browse Courses",
            path: "/search",
          },
          {
            name: "Manage Courses",
            path: "/teaching",
          },
          {
            name: "Manage Classrooms",
            path: "/classroomsManagement",
          },
          {
            name: "My Classes",
            path: "/myclasses",
          },
          {
            name: "Wishlist",
            path: "/favorites",
          },
          {
            name: "My Reviews",
            path: "/",
          },
          {
            name: "Settings",
            path: "/myprofile",
            state: { activeTab: "privacy" },
          }
        );
      }
    }

    setDropdownItems(items);
  };

  window.addEventListener("scroll", () => {
    setTransparent(window.scrollY <= 50 && defaultTransparent);
  });

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <Beta show={showBeta} setShow={setShowBeta} />
      {showBeta ? (
        <div className="h-[140px]"></div>
      ) : (
        <div className="h-[90px]"></div>
      )}
      <nav
        className={styles.navbar_container + (showBeta ? " top-12" : " top-0")}
        style={
          transparent
            ? { backgroundColor: "transparent" }
            : {
                backgroundColor: "#fff",
                borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
              }
        }
      >
        <div className={styles.navbar_inner}>
          <div className="flex items-center gap-8">
            <Link to="/">
              <img className="h-7 sm:h-10" src="/images/logo.svg" alt="Logo" />
            </Link>
            <div className={`hidden ${user ? "" : "sm:block"}`}>
              <LanguageDropdown
                language={userStore.language}
                currency={userStore.currency}
                setLanguage={setLanguage}
                setCurrency={setCurrency}
              />
            </div>
          </div>

          {!user && (
            <div className={styles.navbar_links}>
              <Link to="/courses">Courses</Link>
              <a href="https://community.classx.tech/teach-with-us">
                Teach with us
              </a>
              <a href="https://community.classx.tech/list-your-space/">
                List your space
              </a>
              <a href="https://community.classx.tech/about/">About</a>
            </div>
          )}
          {user && role.toLowerCase() === "student" && (
            <div className={styles.navbar_links}>
              <Link to="/courses">Browse</Link>
              <Link to="/myclasses">Classes</Link>
            </div>
          )}

          {((user && role.toLowerCase() === "teacher") ||
            (user && role.toLowerCase() === "host") ||
            (user && role.toLowerCase() === "admin")) && (
            <div className={styles.navbar_links}>
              <Link to="/courses">Browse</Link>
              <Link to="/teaching">Manage</Link>
              <Link to="/myclasses">Classes</Link>
            </div>
          )}

          {!user ? (
            <>
              <div className={styles.navbar_buttons}>
                <Link to="/login" className={styles.navbar_button}>
                  Sign In
                </Link>
                <Button
                  text="Create an account"
                  onClick={() => navigate("/register")}
                />
              </div>
            </>
          ) : (
            <>
              <div className="flex gap-8 items-center relative">
                <div className={`hidden lg:flex items-center gap-8`}>
                  <LanguageDropdown
                    language={userStore.language}
                    currency={userStore.currency}
                    setLanguage={setLanguage}
                    setCurrency={setCurrency}
                  />
                  <img
                    className="cursor-pointer"
                    src="/images/icons/wishlist.svg"
                    alt=""
                  />
                </div>
                <div
                  className={styles.navbar_user}
                  onClick={() => setDropdownMenu(!dropdownMenu)}
                  ref={userPicRef}
                >
                  <img
                    className={styles.navbar_avatar}
                    src={
                      user?.image && Object.keys(user?.image).length > 0
                        ? `${env.IMAGE_API}${user?.image?.name}`
                        : "images/default-user.svg"
                    }
                    alt="profile"
                  />
                </div>

                {dropdownMenu && (
                  <div
                    className={`${styles.navbar_user_links} absolute left-0 top-14`}
                    ref={dropdownRef}
                  >
                    <div
                      className="flex items-center gap-2.5 p-3.5 border-b border-primary-gray-200 cursor-pointer"
                      onClick={() => navigate("/myprofile")}
                    >
                      <img
                        className={styles.navbar_avatar}
                        src={
                          user?.image && Object.keys(user?.image).length > 0
                            ? `${env.IMAGE_API}${user?.image?.name}`
                            : "images/default-user.svg"
                        }
                        alt="profile"
                      />
                      <div>
                        <div className="text-base text-primary-paradise-pink-900 font-normal">
                          {user.fullName}
                        </div>
                        <div className="text-xs leading-6 text-primary-purple-500 font-medium">
                          {capitalizeFirstLetter(role)} Account
                        </div>
                      </div>
                    </div>
                    <div className="py-2 border-b border-primary-gray-200 pt-1.5 min-w-[258px] flex flex-col justify-start items-start">
                      {dropdownItems.map((item, index) => (
                        <div
                          className={
                            item.name === "Browse Courses"
                              ? "hidden"
                              : "text-sm text-primary-paradise-pink-800 py-2.5 px-5 w-full cursor-pointer font-normal hover:text-primary-paradise-pink-500 hover:bg-primary-paradise-pink-50"
                          }
                          key={index}
                        >
                          <Link to={item.path} state={item.state || {}} >{item.name}</Link>
                        </div>
                      ))}
                    </div>
                    <div
                      className="flex gap-2 justify-center items-center text-sm font-normal text-tertiary-severe-600 mx-2.5 my-3.5 px-3.5 py-3 rounded-xl border border-tertiary-severe-100 bg-tertiary-severe-50 cursor-pointer"
                      onClick={handleLogout}
                    >
                      <img src="/images/icons/logout.svg" alt="" />
                      <span>Log Out</span>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          <div className={styles.navbar_mobile}>
            <img
              src={
                showSidebar
                  ? "/images/icons/close.svg"
                  : "/images/icons/menu.svg"
              }
              className="relative z-50 size-10 sm:size-12"
              alt="menu"
              onClick={() => setShowSidebar(!showSidebar)}
            />

            <div className="relative">
              <div
                className={`fixed inset-0 bg-black transition-opacity duration-200 ${
                  showSidebar ? "opacity-20" : "opacity-0 pointer-events-none"
                }`}
              />
              <div
                className={`fixed top-0 right-0 flex justify-end w-screen transform transition-transform duration-200 ${
                  showSidebar ? "translate-x-0" : "translate-x-full"
                }`}
                style={{ height: showBeta ? "calc(100vh - 3rem)" : "100vh" }}
              >
                <div className="flex flex-col justify-between w-80 h-screen p-6 bg-primary-gray-50">
                  {!user && (
                    <div className="flex gap-7 flex-col text-xl font-semibold py-[69px]">
                      <Link className="py-0.5 px-1" to="/courses">
                        Courses
                      </Link>
                      <a
                        className="py-0.5 px-1"
                        href="https://community.classx.tech/teach-with-us"
                      >
                        Teach with us
                      </a>
                      <a
                        className="py-0.5 px-1"
                        href="https://community.classx.tech/list-your-space/"
                      >
                        List your space
                      </a>
                      <a
                        className="py-0.5 px-1"
                        href="https://community.classx.tech/about/"
                      >
                        About us
                      </a>
                      <div className="py-0.5 px-1">
                        <LanguageDropdown
                          language={language}
                          currency={userStore.currency}
                          setLanguage={setLanguage}
                          setCurrency={setCurrency}
                        />
                      </div>
                    </div>
                  )}
                  {user && (
                    <div className="flex gap-6 flex-col text-xl font-semibold py-[69px]">
                      <div
                        className="flex items-center gap-2.5 cursor-pointer"
                        onClick={() => navigate("/myprofile")}
                      >
                        <img
                          className={styles.navbar_avatar}
                          src={
                            user?.image && Object.keys(user?.image).length > 0
                              ? `${env.IMAGE_API}${user?.image?.name}`
                              : "images/default-user.svg"
                          }
                          alt="profile"
                        />
                        <div>
                          <div className="text-base text-primary-paradise-pink-900 font-normal">
                            {user.fullName}
                          </div>
                          <div className="text-xs leading-6 text-primary-purple-500 font-medium">
                            {capitalizeFirstLetter(role)} Account
                          </div>
                        </div>
                      </div>
                      {dropdownItems.map((item, index) => (
                        <Link
                          key={index}
                          className="py-0.5 px-1 hover:text-primary-paradise-pink-600"
                          to={item.path}
                          
                        >
                          {item.name}
                        </Link>
                      ))}
                      <div className="py-0.5 px-1">
                        <LanguageDropdown
                          language={language}
                          currency={userStore.currency}
                          setLanguage={setLanguage}
                          setCurrency={setCurrency}
                        />
                      </div>
                    </div>
                  )}
                  {!user && (
                    <div className="flex flex-col gap-2.5">
                      <Button
                        type="secondary"
                        text="Sign In"
                        onClick={() => navigate("/login")}
                      />
                      <Button
                        text="Create an account"
                        onClick={() => navigate("/register")}
                      />
                    </div>
                  )}
                  {user && (
                    <div
                      className="flex gap-2 justify-center items-center text-sm font-normal text-tertiary-severe-600 px-3.5 py-3 rounded-xl border border-tertiary-severe-200 bg-tertiary-severe-50 cursor-pointer"
                      onClick={handleMobileLogout}
                    >
                      <img src="/images/icons/logout.svg" alt="" />
                      <span>Log Out</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default observer(Navbar);
