import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "./feedbacks.module.css";
import { useStore } from "../../../../store";
import { useNavigate } from "react-router-dom";

interface CustomDotProps {
  onClick?: () => void;
  active?: boolean;
}

const CustomDot: React.FC<CustomDotProps> = ({ onClick, active }) => {
  return (
    <button
      className={`custom-dot h-[3px] rounded-full mx-1.5 transition-all duration-300 ${
        active
          ? "active bg-primary-paradise-pink-800 w-6"
          : "bg-primary-gray-300 w-5"
      }`}
      onClick={onClick}
    />
  );
};

const Feedbacks: React.FC = () => {
  const navigate = useNavigate();

  const feedbacks = [
    {
      name: "Julia Suszfalak",
      profession: "Co-founder Sleath startup",
      verified: true,
      feedback:
        "Being in a class interacting directly with the best expert in the field is a privilege",
      rating: 5,
      image: "/images/feedbacks/julia.png",
    },
    {
      name: "Katherine Ivanova",
      profession: "CEO Hist.ai",
      verified: true,
      feedback:
        "Is not only learning something from the instructor which is very valuable, but actually the connections with people who share with you either some interests or the pain points",
      rating: 5,
      image: "/images/feedbacks/katherine.png",
    },
    {
      name: "Justine Juillard",
      profession: "Director Berkeley Venture Capital",
      verified: true,
      feedback:
        "As a startup consultant, I think I'm definitely going to be able to enhance my clients' pitches in a very valuable way. I'm so grateful I was given this opportunity to come.",
      rating: 5,
      image: "/images/feedbacks/justine.png",
    },
    {
      name: "Renan Serrano",
      profession: "Co-Founder Good Inc",
      verified: true,
      feedback:
        "Learning from one of the best investors in the world was life changing for me",
      rating: 5,
      image: "/images/feedbacks/renan.png",
    },
    {
      name: "Mikhael Romanenko",
      profession: "Polis Co-Founder",
      verified: true,
      feedback:
        "It's very intimate! I was thinking it was going to be a big conference hall or something but it was almost one on one instruction from one of the best,",
      rating: 5,
      image: "/images/feedbacks/misha.png",
    },
    {
      name: "Jing Yang",
      profession: "Founder of FlowMingle.Live",
      verified: true,
      feedback:
        "Bill has a lot of experience in this industry and he is very insightful.",
      rating: 5,
      image: "/images/feedbacks/jin.png",
    },
    {
      name: "Deepak Jha",
      profession: "CEO @ XpBrand.AI",
      verified: true,
      feedback:
        "ClassX gives me the confidence of how to pitch in the right mindset ”forget everything you have learned this far!”",
      rating: 5,
      image: "/images/feedbacks/deepak.png",
    },
    {
      name: "Adithi Jha",
      profession: "Student",
      verified: true,
      feedback:
        "I got to learn a lot about pitching and the correct way to pitch which i think will be really helpful in my journey in the future",
      rating: 5,
      image: "/images/feedbacks/adithi.png",
    },
    {
      name: "Dhouha DAASSI",
      profession: "COO - Stealth Biotech",
      verified: true,
      feedback:
        "During our Class, we got a chance to learn a lot about how to pitch successfully and to influence investors",
      rating: 5,
      image: "/images/feedbacks/dhouha.png",
    },
  ];

  const responsive = {
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
      partialVisibilityGutter: 30,
    },
  };

  return (
    <div className="mt-10">
      <div className={styles.gradient}></div>
      <div className={styles.feedbacks}>
        <div className="flex justify-center items-center gap-4 p-2.5">
          <img src="/images/icons/star2.svg" />
          <h3 className="text-body-large font-body-large-bold sm:text-subheading sm:font-subheading text-center">
            What our students say
          </h3>
          <img src="/images/icons/star1.svg" />
        </div>

        <div className="block sm:hidden">
          <Carousel
            responsive={responsive}
            infinite
            autoPlay
            autoPlaySpeed={3000}
            arrows={false}
            showDots
            itemClass="px-2"
            partialVisbile
            containerClass="px-4"
            customDot={<CustomDot />}
          >
            {feedbacks.map((feedback, index) => (
              <FeedbackItem key={index} feedback={feedback} />
            ))}
          </Carousel>
        </div>
        <div className="hidden sm:block">
          <div className="flex justify-center space-x-4 mb-4">
            <div className="min-w-[289px] max-w-[289px]">
              <FeedbackItem key={0} feedback={feedbacks[0]} />
            </div>
            <div className="min-w-[289px] max-w-[289px]">
              <FeedbackItem key={1} feedback={feedbacks[1]} />
            </div>
            <div className="min-w-[289px] max-w-[289px]">
              <FeedbackItem key={2} feedback={feedbacks[2]} />
            </div>
            <div className="min-w-[289px] max-w-[289px]">
              <FeedbackItem key={3} feedback={feedbacks[3]} />
            </div>
          </div>

          <div className="flex justify-center space-x-4 mb-4">
            <div className="min-w-[289px] max-w-[289px]">
              <FeedbackItem key={4} feedback={feedbacks[4]} />
            </div>
            <div className="min-w-[289px] max-w-[289px]">
              <FeedbackItem key={5} feedback={feedbacks[5]} />
            </div>
            <div className="min-w-[289px] max-w-[289px]">
              <FeedbackItem key={6} feedback={feedbacks[6]} />
            </div>
          </div>

          <div className="flex justify-center space-x-4">
            <div className="min-w-[289px] max-w-[289px]">
              <FeedbackItem key={7} feedback={feedbacks[7]} />
            </div>
            <div className="min-w-[289px] max-w-[289px]">
              <FeedbackItem key={8} feedback={feedbacks[8]} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FeedbackItem: React.FC<{ feedback: any }> = ({ feedback }) => {
  return (
    <div className="flex flex-col items-center gap-4 px-5 py-4 border border-black border-opacity-5 bg-primary-gray-50 rounded-2xl h-fit mb-5 sm:m-0">
      <div className="flex flex-col items-center gap-2">
        <img
          className="rounded-full fit-cover"
          src={feedback.image}
          alt={feedback.name}
          width={84}
          height={84}
        />
        <div className="flex flex-col items-center gap-0.5">
          <div className="body-large">{feedback.name}</div>
          <div className="caption-text text-primary-paradise-pink-600">
            {feedback.profession}
          </div>
          {feedback.verified && (
            <div className="flex gap-0.5 items-center caption-text-bold text-tertiary-success-700">
              <img src="/images/icons/success-green.svg" />
              <span>Verified Student</span>
            </div>
          )}
        </div>
      </div>

      <p className="body-small text-center">{feedback.feedback}</p>

      <div className="flex gap-0.5">
        {new Array(Math.ceil(feedback.rating)).fill(null).map((_, i) => (
          <img key={i} src="/images/icons/star.svg" />
        ))}
      </div>
    </div>
  );
};

export default observer(Feedbacks);
