export const validateEmail = (email: string) => {
  const trimmedEmail = email.trim();
  const valid = String(trimmedEmail)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  return valid ? true : false;
};

export const validatePassword = (password: string) => {
  return String(password).length >= 8;
};

export const validateName = (name: string) => {
  return String(name).length >= 4;
};

export const validateConfirmPassword = (
  password: string,
  confirmPassword: string
) => {
  return password === confirmPassword;
};
