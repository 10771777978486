import React from "react";

import styles from "./topics.module.css";
import TopicItem from "./TopicItem";

const Topics: React.FC = () => {
  const topicsItems = [
    {
      title: "Articifial Intelligence",
      description:
        "Explore machine learning, neural networks, and AI-driven technologies.",
      icon: "/images/icons/topics/ai.svg",
    },
    {
      title: "Coding",
      description:
        "Learn to program with various languages such as Python, HTML, CSS, Java, and more.",
      icon: "/images/icons/topics/coding.svg",
    },
    {
      title: "Design",
      description:
        "Unleash your creativity with courses on Graphic, UI/UX, Product Design, and more.",
      icon: "/images/icons/topics/design.svg",
    },
    {
      title: "Product",
      description:
        "Master the skills to develop, launch, and manage successful products.",
      icon: "/images/icons/topics/product.svg",
    },
    {
      title: "Data Science",
      description:
        "Learn to collect, process, and analyze data for smarter decision-making & data visualization.",
      icon: "/images/icons/topics/data-science.svg",
    },
    {
      title: "Blockchain",
      description:
        "Understand decentralized technology to create secure and transparent blockchain solutions.",
      icon: "/images/icons/topics/blockchain.svg",
    },
    {
      title: "Digital Marketing",
      description:
        "Learn strategies to boost online presence through SEO, social media, and more.",
      icon: "/images/icons/topics/digital-marketing.svg",
    },
    {
      title: "Life Skills",
      description:
        "Build practical skills like communication, time management, and problem-solving.",
      icon: "/images/icons/topics/life-skills.svg",
    },
  ];
  return (
    <div className={styles.topics_container}>
      <div className={styles.topics}>
        <div className={styles.topics_heading}>
          <h2 className="subheading">Featured Topics</h2>
          <span className="body-medium">
            A wide range of topics to learn or enhance your skills
          </span>
        </div>

        <div className={styles.topics_list}>
          {topicsItems.map((item) => (
            <TopicItem
              key={item.title}
              title={item.title}
              description={item.description}
              icon={item.icon}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Topics;
