import { useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import { CourseComponents, Footer, Navbar } from "../../../components";
import { useStore } from "../../../store";
import styles from "./Cohort.module.css";
import Container from "src/components/shared/Container";


function Cohorte() {
  const params = useParams();
  const { courseStore, userStore } = useStore();

  const course: any = courseStore.courseDetails;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (params.id) {
      courseStore.getCohortById(params.id);
    }
    userStore.getUser();
  }, []);

  return (
    <>
        <Navbar />

      <CourseComponents.CourseBreadcrumbs type="class" />


      <CourseComponents.CourseHeader type="class" />
      <Container>


      <CourseComponents.CourseNavBar type="class" />

      <CourseComponents.AboutCourse type="class" />
      <CourseComponents.CourseContent />

      <CourseComponents.CourseClassrooms />

      {course?.tutors &&
      course?.tutors?.fullName &&
      course?.tutors.fullName !== "wecodeland" ? (
        <CourseComponents.AboutInstructor type="class" />
      ) : null}

      {course?.startTime && course?.endTime && (
        <CourseComponents.CourseSchedule />
      )}

      {(course?.sessionCourse?.objectifs?.image?.name || course?.sessionCourse?.perspectives?.images?.name) ? (
        <CourseComponents.CourseGoals type="class" />
      ) : null}
            </Container>

      <CourseComponents.CourseStories type="class" />
      <Container>

      <CourseComponents.FAQs />
      </Container>

      <Footer />
      
    </>
  );
}

export default observer(Cohorte);
