import styles from "./categories.module.css";
import { observer } from "mobx-react";
import { useStore } from "../../../../store";

function Categories() {
    const { courseStore } = useStore();

    const categories: any[] = courseStore.categories || [];
    
    return (
        <div className={styles.categories}>
            <div className={styles.categories_content}>
                <div className={styles.categories_heading}>
                    <h2 className={styles.categories_title}>Develop your career by learning desired skills</h2>
                    <p className={styles.categories_subtitle}>Learning new skills will you to integrate into the labour market and catch new opportunities, and develop your career.</p>
                </div>

                <div className={styles.categories_list}>
                    {categories.map((category: any, index ) => (
                        <div key={index} className={styles.categories_item}>
                            <h3 className={styles.categories_item_title}>{category?.name}</h3>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default observer(Categories);