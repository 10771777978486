import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { ToastContainer, toast } from "react-toastify";
import env from "../../../config/env";

import styles from "../auth.module.css";
import { Input, Button } from "../../../components";
import { useStore } from "../../../store";
import {
  validateEmail,
  validatePassword,
  validateName,
  validateConfirmPassword,
} from "../../../utils/validation";
import { set } from "mobx";

function Register() {
  const navigate = useNavigate();
  const { userStore } = useStore();

  const LinkedInApi = {
    clientId: env.LINKEDIN_CLIENT_ID,
    redirectUrl: env.LINKEDIN_REDIRECT_URL,
    oauthUrl: env.LINKEDIN_OAUTH_URL,
    scope: env.LINKEDIN_SCOPE,
    state: env.LINKEDIN_STATE,
  };

  const [email, setEmail] = React.useState<string>("");
  const [fullName, setFullName] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [confirmPassword, setConfirmPassword] = React.useState<string>("");
  const [passwordError, setPasswordError] = React.useState<string>("");

  const isLinkedInAuthRef = useRef<boolean>(false);

  const [agree, setAgree] = React.useState<boolean>(false);

  useEffect(() => {
    if (userStore?.user !== null) {
      navigate("/");
    }

    if (userStore.userData?.email) {
      setEmail(userStore.userData.email);
      setFullName(userStore.userData.fullName || "");
      setPassword(userStore.userData.password || "");
      setConfirmPassword(userStore.userData.password || "");
    }

    if (window.opener && window.opener !== window) {
      const code = getCodeFromWindowURL(window.location.href);
      window.opener.postMessage({ type: "code", code: code }, "*");
      window.close();
    }
    window.addEventListener("message", handlePostMessage);
    return () => window.removeEventListener("message", handlePostMessage);
  }, []);

  const handlePostMessage = (event: any) => {
    if (event.data.type === "code" && !isLinkedInAuthRef.current) {
      isLinkedInAuthRef.current = true;
      const { code } = event.data;
      handleLinkedInSuccess(code);
    }
  };

  const getCodeFromWindowURL = (url: any) => {
    const popupWindowURL = new URL(url);
    return popupWindowURL.searchParams.get("code");
  };

  const navigateToCompleteRegister = () => {
    const user = {
      email: email,
      fullName: fullName,
      password: password,
    };
    userStore.setUserData(user);
    navigate("/complete-register");
  };

  const handleOnChangeAgree = () => {
    setAgree(!agree);
  };

  const googleSignUp = useGoogleLogin({
    onSuccess: (tokenResponse) => handleGoogleSuccess(tokenResponse),
  });

  const handleGoogleSuccess = async (response: any) => {
    try {
      const googleToken = response.access_token;
      const res = await userStore.signUpWithGoogle(googleToken);
      console.log("res", res);

      if (res) {
        navigate("/");
      } else {
        toast("Google Sign up failed!");
      }
    } catch (error: any) {
      if (error.response.data.error.code === "USER_EXISTS") {
        handleGoogleLogin(response);
      } else {
        toast(error.response.data.error.message);
      }
    }
  };

  const handleGoogleLogin = async (response: any) => {
    try {
      const token = response.access_token;
      const res = await userStore.loginWithGoogle(token);

      if (res) {
        navigate("/");
      } else {
        toast("Google login failed!");
      }
    } catch (error: any) {
      console.log("error", error);

      toast(error.response.data.error.message);
    }
  };

  const handleLinkedInSuccess = async (code: any) => {
    try {
      const linkedinToken = code;
      const res = await userStore.signUpWithLinkedIn(linkedinToken);
      if (res) {
        navigate("/");
      } else {
        toast("LinkedIn Sign up failed!");
      }
    } catch (error: any) {
      toast(error.response.data.error.message);
    }
  };

  const showPopup = () => {
    const { clientId, redirectUrl, oauthUrl, scope, state } = LinkedInApi;
    const oauthUrlComplete = `${oauthUrl}&client_id=${encodeURIComponent(
      clientId!
    )}&scope=${encodeURIComponent(
      scope!
    )}&state=${state}&redirect_uri=${redirectUrl}`;
    const width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;
    window.open(
      oauthUrlComplete,
      "Linkedin",
      "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };

  return (
    <main className={styles.main}>
      <ToastContainer theme="dark" />
      <div className={styles.left_column}>
        <img
          src="/images/login-cover.png"
          alt="Login Cover"
          className={styles.cover_image}
        />
      </div>

      <div className={styles.right_column}>
        <div className={styles.right_column_content}>
          <Link className={styles.back_button} to="/">
            <img
              src="/images/icons/arrow-left.svg"
              alt="Arrow Left"
              width={5}
              height={10}
            />
            <span className={styles.back_text}>Home</span>
          </Link>

          <span className={styles.title}>Let&apos;s get started!</span>
          <span className={styles.sub_title}>Welcome aboard!</span>

          <div className={styles.form}>
            <Input
              label="Full name"
              value={fullName}
              validation={validateName}
              onChange={(e) => setFullName(e.target.value)}
            />
            <Input
              label="Email"
              value={email}
              validation={validateEmail}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              label="Password"
              type="password"
              value={password}
              validation={validatePassword}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Input
              label="Confirm Password"
              type="password"
              value={confirmPassword}
              validation={(value) => validateConfirmPassword(password, value)}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />

            <div className={styles.checkbox_container}>
              <input
                type="checkbox"
                id="agree"
                name="agree"
                value="Bike"
                checked={agree}
                onChange={handleOnChangeAgree}
              />
              <label htmlFor="agree">
                {" "}
                I agree with the{" "}
                <a
                  rel="noreferrer"
                  href="https://blog.classx.tech/terms"
                  target="_blank"
                  className={styles.privacy_link}
                >
                  Terms
                </a>{" "}
                and{" "}
                <a
                  rel="noreferrer"
                  href="https://blog.classx.tech/privacy-policy/"
                  target="_blank"
                  className={styles.privacy_link}
                >
                  Privacy
                </a>
              </label>
              <br></br>
            </div>
            <Button
              type="primary"
              text="Sign Up"
              onClick={() => navigateToCompleteRegister()}
              isDisabled={
                !validateEmail(email) ||
                !validatePassword(password) ||
                !validateName(fullName) ||
                !agree ||
                !validateConfirmPassword(password, confirmPassword)
              }
            />
          </div>

          <div className={styles.separator}>
            <hr className={styles.separator_line} />
            <span className={styles.separator_text}>Or continue with</span>
            <hr className={styles.separator_line} />
          </div>

          <div className={styles.social_buttons}>
            <Button
              type="tertiary"
              text="Continue with Google"
              onClick={() => googleSignUp()}
              icon="/images/icons/google-colored.svg"
            />
            <Button
              type="tertiary"
              text="Continue with Linkedin"
              onClick={showPopup}
              icon="/images/icons/linkedin-colored.svg"
            />
          </div>

          <div className={styles.footer}>
            <span className={styles.footer_text}>
              Already have an account?{" "}
            </span>
            <Link to="/login">
              <span className={styles.footer_link}>Sign in</span>
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}

export default observer(Register);
