import { useEffect, useState } from "react";

let isScriptLoaded = false;

export function useGoogleMaps(apiKey: string) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isScriptLoaded) {
      setIsLoaded(true);
      return;
    }

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.onload = () => {
      isScriptLoaded = true; // Prevent re-loading
      setIsLoaded(true);
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Clean up if needed
    };
  }, [apiKey]);

  return isLoaded;
}
