import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import styles from "./details.module.css";
import { Button, Input } from "../../../../components";
import { useStore } from "../../../../store";
import env from "../../../../config/env";
import { payForSession, addSessionToUser } from "src/store/session";

function PaymentDetails({
  status,
  setStatus,
  agree,
  setAgree,
  submitRef,
  paymentMethod,
}: {
  status: 1 | 2;
  setStatus: React.Dispatch<React.SetStateAction<1 | 2>>;
  agree: boolean;
  setAgree: React.Dispatch<React.SetStateAction<boolean>>;
  submitRef: React.RefObject<HTMLButtonElement>;
  paymentMethod:
    | "PostWire"
    | "Onsite"
    | "OnlinePayment"
    | "KonnectPayment"
    | "StripePayment";
}) {
  const navigate = useNavigate();
  const { courseStore, userStore, paymentStore } = useStore();

  const [code, setCode] = React.useState<string>("");

  const course: any = courseStore.courseDetails;

  const [price, setPrice] = useState<any>(0);
  const [discount, setDiscount] = useState<any>(0);
  const [total, setTotal] = useState<any>(0);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [couponApplied, setCouponApplied] = useState(false);

  // useEffect(() => {
  //   if (course) {
  //     setPrice(course?.price);
  //   }
  // });

  const tax = 0.2;

  const [isPaymentDisabled, setIsPaymentDisabled] = useState(false);

  const [isStripeProcessing, setIsStripeProcessing] = useState(false);
  const [stripeError, setStripeError] = useState<string | null>(null);
  const [NewPrice, setNewPrice] = useState<number>(0);

  useEffect(() => {
    if (course && price !== undefined && discount !== undefined) {
      setNewPrice(price - discount);
    }
    if (course) {
      setPrice(course.price);
      setTotal(course.price);
    }
    if (paymentMethod === "StripePayment") {
      setIsPaymentDisabled(true);
    } else {
      setIsPaymentDisabled(false);
    }
  }, [course, paymentMethod, price, discount, couponApplied]);

  const handleOnChangeAgree = () => {
    setAgree(!agree);
  };

  const onSiteAndWirePayment = async () => {
    setButtonLoading(true);
    try {
      // Call the createWiringOrOnsitePaymentSession only for "PostWire" or "Onsite"
      if (paymentMethod !== "Onsite" && paymentMethod !== "PostWire") {
        throw new Error("Invalid payment method for onSiteAndWirePayment");
      }

      const response = await paymentStore.createWiringOrOnsitePaymentSession(
        course.id,
        paymentMethod
      );

      if (response?.status === 200) {
        navigate("/successfully-enrolled");
      } else {
        navigate("/paymentFailed");
      }
    } catch (error) {
      console.error("Error during Onsite/Wiring Payment:", error);
      navigate("/paymentFailed");
    } finally {
      setButtonLoading(false);
    }
  };

  const pay = async (amount: number, currency: string) => {
    if (status === 1) {
      if (submitRef.current) {
        let userLocationStorage = localStorage.getItem("userLocation");
        if (userLocationStorage) {
          let userLocationObject = JSON.parse(userLocationStorage);
          if (
            userLocationObject &&
            userLocationObject.countryCode &&
            userLocationObject.countryCode !== ""
          ) {
            if (userLocationObject.countryCode === "US") {
              await handleStripePayment();
            } else {
              submitRef.current.click();
            }
          } else {
            if (
              userStore.user &&
              userStore.user.country &&
              !userStore.user.country.toLowerCase().includes("tunisia")
            ) {
              await handleStripePayment();
            } else {
              submitRef.current.click();
            }
          }
        } else {
          if (
            userStore.user &&
            userStore.user.country &&
            !userStore.user.country.toLowerCase().includes("tunisia")
          ) {
            await handleStripePayment();
          } else {
            submitRef.current.click();
          }
        }
      }
    } else {
      if (!agree) {
        toast("Please check to acknowledge our Privacy & Terms Policy !");
      } else {
        if (paymentMethod === "KonnectPayment") {
          setButtonLoading(true);
          const data = {
            sessionId: course.id,
            amount,
            currency,
          };
          const result = await payForSession(data);
          if (result) {
            window.location.href = result.payUrl;
          } else {
            toast.error("Something went wrong !");
            setButtonLoading(false);
            setCouponApplied(false);
          }
        }
        if (paymentMethod === "StripePayment") {
          setButtonLoading(true);
          handleStripePayment();
        }

        if (paymentMethod === "Onsite" || paymentMethod === "PostWire") {
          await onSiteAndWirePayment();
          setButtonLoading(true);
        }
      }
    }
  };

  const handleStripePayment = async () => {
    setIsStripeProcessing(true);
    setStripeError(null);
    try {
      await paymentStore.createCheckoutSession(
        NewPrice || 0,
        "usd",
        courseStore.courseDetails?.id
      );
    } catch (err) {
      console.error("Error during Stripe Checkout:", err);
      setStripeError("Failed to initiate payment. Please try again.");
      setIsStripeProcessing(false);
    }
  };

  const useCode = async () => {
    const response: any = await courseStore.subscribeWithCoupon({
      code: code,
      sessionId: course.id,
      studentId: userStore.user?.id,
    });

    if (response === null) {
      toast.error("Invalid Coupon!");
      return;
    }

    console.log("Response:", response);
    console.log("Current price:", price);
    console.log("Current discount:", response.missingAmount - price);
    console.log("Current total:", price - (response?.missingAmount || 0));

    setDiscount((price - response.missingAmount).toFixed(1));
    const updatedNewPrice = response.missingAmount;
    setNewPrice(updatedNewPrice);
    setCouponApplied(true);
    toast.success("Coupon Applied Successfully!");

    // If the coupon fully covers the cost
    if (updatedNewPrice <= 0) {
      try {
        await addSessionToUser(course.id, userStore.user?.id);
        navigate("/myclasses");
      } catch (err) {
        console.error("Error subscribing user automatically:", err);
        toast.error(
          "Unable to complete automatic subscription. Please try again."
        );
      }
    }
  };

  if (!course) {
    return <div>Loading course details...</div>;
  }

  return (
    <div className={styles.payment_details}>
      <div className={styles.course_details}>
        <img
          src={`${env.IMAGE_API}${
            course?.sessionCourse?.image?.name || course?.image?.name
          }`}
          alt={course?.title}
        />

        <div className={styles.course_info}>
          <div className={styles.course_info_header}>
            {course?.domains?.map((domain: any, i: number) => (
              <span key={i} className={styles.course_category}>
                {domain?.name || domain}
              </span>
            ))}
            <span className={styles.course_title}>{course?.title}</span>
            <p>{course?.description}</p>
          </div>

          <div className={styles.course_footer}>
            <div className={styles.course_instructor}>
              <img
                src="/images/we-code.jpeg"
                alt={course?.tutors?.fullName}
                className={styles.course_instructor_image}
                width={20}
                height={20}
              />
              <p className={styles.course_instructor_name}>
                {course?.tutors?.fullName}
              </p>
            </div>
            <p className={styles.course_price}>{price}</p>
          </div>
        </div>
      </div>

      <div className={styles.payment_billing}>
        <h4>Billing Summary</h4>

        <div className={styles.payment_billing_details}>
          <div className={styles.payment_billing_details_item}>
            <span>Subtotal</span>
            <span>{price}</span>
          </div>
          <div className={styles.payment_billing_details_item}>
            <span>Discount</span>
            <span>{discount}</span>
          </div>
          {/* <div className={styles.payment_billing_details_item}>
            <span>Tax</span>
            <span>{price * tax}</span>
          </div> */}

          <span className={styles.promo_code_text}>
            Do you have a promo code ?
          </span>
        </div>

        <div className={styles.promo_code}>
          <div style={{ flex: 1 }}>
            <Input
              fullWidth
              label="Promo Code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
          {couponApplied ? (
            <Button text="Coupon Applied" width="60%" isDisabled />
          ) : (
            <Button text="Apply" width="30%" onClick={useCode} />
          )}
        </div>

        <hr />

        <div
          className={`${styles.payment_billing_details_item} ${styles.payment_billing_details_total}`}
        >
          <span>Total</span>
          <span>{NewPrice}</span>
        </div>

        <div className={styles.checkbox_container}>
          <input
            type="checkbox"
            id="agree"
            name="agree"
            value="Bike"
            checked={agree}
            onChange={() => setAgree(!agree)}
          />
          <label htmlFor="agree">
            Please check to acknowledge our{" "}
            {/* <a
              rel="noreferrer"
              href="https://blog.classx.tech/terms"
              target="_blank"
              className={styles.privacy_link}
            >
              Privacy & Terms Policy
            </a> */}
            <a
              rel="noreferrer"
              href="https://blog.classx.tech/privacy-policy/"
              target="_blank"
              className={styles.privacy_link}
            >
              Privacy
            </a>
            <span className={styles.and_symbol}> & </span>
            <a
              rel="noreferrer"
              href="https://blog.classx.tech/terms"
              target="_blank"
              className={styles.privacy_link}
            >
              Terms Policy
            </a>
          </label>
        </div>

        <Button
          text={`Pay ${NewPrice} ${userStore.currency}`}
          onClick={() => pay(NewPrice || 0, userStore.currency || "TND")}
          isDisabled={!agree || paymentMethod === "OnlinePayment"}
          isLoading={buttonLoading}
        />
      </div>
      <ToastContainer theme="dark" />
    </div>
  );
}

export default observer(PaymentDetails);
