import React, { useRef, useState } from "react";

import styles from "./uploadImage.module.css";
import env from "../../../config/env";

interface InputProps {
  image: any;
  width?: string;
  setImage: (image: any) => void;
}

const UploadImage: React.FC<InputProps> = ({ image, width, setImage }) => {
  const [dragging, setDragging] = useState(false);
  const pictureUploadRef = useRef<HTMLInputElement>(null);

  const handlePictureUpload = (): void => {
    if (pictureUploadRef.current && pictureUploadRef.current.files) {
      const file = pictureUploadRef.current.files[0];
      setImage(file);
    }
  };

  const triggerFileInput = (): void => {
    pictureUploadRef.current?.click();
  };
  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      setImage(file);
    }
  };

  return (
    <div className={styles.input_container}>
      <div
        className={`${styles.upload_picture} w-full md:w-[${width || "100%"}]`}
        style={{
          backgroundColor: dragging ? "lightblue" : "#f5f5f5",
        }}
        onClick={triggerFileInput}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragEnter}
        onDrop={handleDrop}
      >
        <div className={styles.default_picture}>
          {dragging ? (
            <div>Drop Your Picture Here</div>
          ) : image ? (
            image.originalFilename ? (
              <img src={`${env.IMAGE_API}${image.name}`} alt="" />
            ) : (
              <img src={URL.createObjectURL(image)} alt="" />
            )
          ) : (
            <>
              <div>+</div>
              <div>Upload</div>
            </>
          )}
        </div>
      </div>
      <input
        className="fileInput"
        ref={pictureUploadRef}
        type="file"
        id="picture"
        onChange={handlePictureUpload}
      />
    </div>
  );
};

export default UploadImage;
