import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteSession, Session } from "src/store/session";

const SessionRows: React.FC<{
  data: Session[];
  handleDeleteSession: () => void;
}> = ({ data, handleDeleteSession }) => {
  const navigate = useNavigate();

  const [dropdown, setDropdown] = useState<boolean[]>(
    new Array(data?.length).fill(false)
  );
  const dropdownRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      dropdownRefs.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target as Node)) {
          setDropdown((prev) =>
            prev.map((item, i) => (i === index ? false : item))
          );
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const options = [
    {
      name: "Edit Class",
      onClick: (id: string | undefined) => navigate("/add-class/edit/" + id),
    },
    {
      name: "Cancel Class",
      onClick: async (id: string | undefined) => {
        await deleteSession(id);
        handleDeleteSession();
      },
    },
  ];

  const handleDate = (date: Date) => {
    return date ? date.toString().substring(0, 10).replace(/-/g, "/") : "";
  };

  const handleDifficultColor = (difficulty: string | undefined) => {
    switch (capitalizeFirstLetter(difficulty)) {
      case "Beginner":
        return "rgb(0, 191, 165)";
      case "Intermediate":
        return "rgb(255, 138, 101)";
      case "Advanced":
        return "rgb(230, 65, 61)";
      default:
        return "#000000";
    }
  };

  function capitalizeFirstLetter(str: string | undefined): string {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <>
      {data.map((session, index) => (
        <tr key={index}>
          <td className="name">{session.title}</td>
          <td>{session.tutors?.fullName ?? ""}</td>
          <td>{session.sessionBooking?.[0]?.bookingRoom?.name ?? ""}</td>
          <td style={{ color: "#CA2D5D" }}>
            {session.subscribedNumber}/{session.maxPlace} Participants
          </td>
          <td style={{ color: handleDifficultColor(session.difficulty) }}>
            {capitalizeFirstLetter(session.difficulty)}
          </td>
          <td>
            {handleDate(session.start)} <br /> {handleDate(session.end)}
          </td>
          <td id="options">
            <img
              src="/images/icons/options.svg"
              alt=""
              onClick={() =>
                setDropdown((prev) =>
                  prev.map((item, i) => (i === index ? !item : item))
                )
              }
            />
            {dropdown[index] && (
              <div
                className="options"
                ref={(el) => (dropdownRefs.current[index] = el)}
              >
                {options.map((option, index) => (
                  <Fragment key={index}>
                    <span
                      className="link"
                      onClick={() => option.onClick(session?.id)}
                    >
                      {option.name}
                    </span>
                    {index !== options.length - 1 && <hr />}
                  </Fragment>
                ))}
              </div>
            )}
          </td>
        </tr>
      ))}
    </>
  );
};

export default SessionRows;
